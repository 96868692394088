import React from "react";
import { Modal, Button } from "react-bootstrap";

function Title_7(props) {
  return (
    <div className="maplayout-page">
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="app-model sampleRequest-model"
      id="drpro-select">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter " className="w-100  pb-0 blue-title-h6">
        Edit Profile

             </Modal.Title>
      </Modal.Header>
      <Modal.Body className="app-model-body">
      <form className="profile-form p-0 border-0">
            <div className="form-group">
              <label for="text">First Name*</label>
              <input type="text" className="form-control" placeholder="Enter First Name" value="John" />
            </div>
            <div className="form-group">
              <label for="text">Last Name*</label>
              <input type="text" className="form-control" placeholder="Enter Last Name" value="Doe" />
            </div>
            <div className="form-group">
              <label for="email">Email Address*</label>
              <input type="email" className="form-control" placeholder="Enter Email" value="johndoe123@gmail.com"
                id="email"/>
            </div>
            <div className="form-group">
              <label for="text">Phone Number*</label>
              <input type="text" className="form-control" placeholder="Enter Phone No." value="9876543210" />
            </div>
            <div className="row  edit-btn-row">
              <div className="col-12 text-center   ">
               
                <button type="button" className="btn btn-outline-primary blue-btn-bg w-25 mr-1">Save</button>
                <button type="button" className="btn btn-outline-secondary blue-btn-bg bg-cancel w-25 ">Cancel</button>
              </div>
            </div>
          </form>

      </Modal.Body>
     
    </Modal>
    </div>
  );
}

export default Title_7;
