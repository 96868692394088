// Declare all application const.

export const API_URL = "https://recordlinc.com/rlapi/api";
// export const API_URL = "https://4c1f453ebf09.ngrok.io/api";
export const RecordLincURL = "https://recordlinc.com/";

// Set comman Header value for API calls
export const HEADER = {
  headers: {
    "Content-Type": "application/json",
    Company_Id: localStorage.getItem("CompanyId"),
    access_token: localStorage.getItem("token"),
  },
};

export const HEADERMultipart = {
  headers: {
    access_token: localStorage.getItem("token"),
    "Content-Type": "multipart/form-data",
    Company_Id: localStorage.getItem("CompanyId"),
  },
};
