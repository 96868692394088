import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Modal, Button } from "react-bootstrap";
import Api from "../API/profile";
import { Spinner } from "reactstrap";
import { RecordLincURL } from "../config/appConstatnts";
//component import
import * as inputFun from "../common-components/FormInputFunctions";
import { ToastContainer, toast } from "react-toastify";
export default function AddProfilePicModal(props) {
  const [loader, setLoader] = useState(false);
  const [reLoader, setReLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [error, setError] = useState("");
  const handleInputChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setError("");
  };
  const onModalClick = () => {
    setError("");
    setSelectedFile();
    props.onHide();
  };

  const onClickRemove = () => {
    setReLoader(true);
    Api.removeProfileImage()
      .then((res) => {
        props.onHide();
        setReLoader(false);
        let userData = JSON.parse(localStorage.getItem("userData"));
        userData.ImageUrl = "";
        localStorage.setItem("userData", JSON.stringify(userData));
        toast.success("Successfully uploaded image!");
        props.reload();
        setTimeout(() => {
          setLoader(false);
          props.onHide();
        }, 1000);
      })
      .catch((error) => {
        setReLoader(false);
        toast.error("Something went wrong!");
      });
  };
  
  const handelSubmit = (event) => {
    event.preventDefault();
    setError("");
    const data = new FormData();
    if (
      selectedFile != null ||
      (selectedFile != "" && selectedFile != undefined)
    ) {
      setLoader(true);
      data.append("file", selectedFile);
      Api.postProfileImage(data)
        .then((res) => {
          let userData = JSON.parse(localStorage.getItem("userData"));
          userData.ImageUrl =
            RecordLincURL + "DentistImages/" + res.data.Result;
          localStorage.setItem("userData", JSON.stringify(userData));
          toast.success("Successfully uploaded image!");
          props.reload();
          setTimeout(() => {
            setLoader(false);
            props.onHide();
          }, 1000);
        })
        .catch((error) => {
          setLoader(false);
          toast.error("Something went wrong!");
        });
    } else {
      setError("Please select profile picture!");
    }
  };

  return (
    <div className="maplayout-page">
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="app-model location-profile-model"
        id="drpro-select"
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="w-100  pb-0 blue-title-h6"
          >
            Add Profile Image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="app-model-body">
          <form>
            <div className="row ">
              <div className=" col-12 ">
                <div className="form-group app-ctrl has-value">
                  <label className="required pb-2">Upload Image</label>
                  <input
                    type="file"
                    className="form-control"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={handleInputChange}
                  />
                  {error != "" && <div className="err-message">{error}</div>}
                </div>
              </div>
            </div>
            <div className="btn-box d-flex">
              <button
                type="button"
                className={`btn app-btn lightgray-btn large-btn mr-2 ${reLoader ? "btn-loading-state" : ""}` }
                onClick={onClickRemove}
              >
                <div className="button-inner">
                  {reLoader && <Spinner style={{ verticalAlign: "middle" }} />}
                  <span className="btn-text">Remove</span>
                </div>
              </button>
              <button
                type="button"
                className="btn app-btn lightgray-btn large-btn mr-2"
                onClick={onModalClick}
              >
                cancel
              </button>
              <button
                type="button"
                onClick={handelSubmit}
                className={`btn app-btn large-btn ${loader ? "btn-loading-state" : ""}` }
              >
                <div className="button-inner">
                {loader && <Spinner style={{ verticalAlign: "middle" }} />}
                <span className="btn-text">Save</span>
                </div>
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

{/* <EditUserProfileModel
            show={EditProfileModelShow}
            userData={userData}
            selectedSpecialty={selectedSpecialty}
            specialty={specialty}
            reload={reload}
            onHide={() => SetEditProfileModel(false)}
          /> */}
