import React, { useEffect, useState } from "react";
import { Nav, Label, Input, Navbar, Dropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AppNavbar from "./common-components/AppNavbar";
import Select2 from "react-select2-wrapper";

import App from "./App";
// import ApexCharts from "react-apexcharts";
import Chart from "react-apexcharts";
import Card1 from "../src/assets/images/bar-chart.png";
import Card2 from "../src/assets/images/totalcollection.svg";
import Card3 from "../src/assets/images/user.png";
import Card4 from "../src/assets/images/new-patients.svg";

import "./assets/css/style.css";
import "./assets/css/responsive.css";

import { useHistory } from "react-router-dom";
import AppSectionLoader from "./common-components/AppSectionLoader";

import Api from "./API/dashboard";

function Dashboard_historical() {
  let history = useHistory();

  const [provider, setProvider] = useState({
    DoctorId: 0,
    Salutation: "",
    FirstName: "",
    LastName: "",
    FullName: "",
  });

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [dashboardSummaryModel, setDashboardSummaryModel] = useState({
    AccountId: 0,
    UserId: 0,
    ChartType: 3,
    StartDate: "",
    EndDate: "",
  });

  const dummySummaryData = {
    TotalProduction: {
      value: 0,
      imageUrl: "",
      title: "Total Production",
    },
    TotalCollections: {
      value: 0,
      imageUrl: "",
      title: "Total Collections",
    },
    ActivePatients: {
      value: 0,
      imageUrl: "",
      title: "Unscheduled Active Patients",
    },
    NewPatients: {
      value: 0,
      imageUrl: "",
      title: "New Patients",
    },
  };
  const [dashboardSummaryResModel, setDashboardSummaryResModel] =
    useState(dummySummaryData);

  const [dashboardSections, setDashboardSections] = useState({
    doctor: null,
    hygiene: null,
    frontOffice: null,
    referralSource: null,
  });

  const dummyDoctor = {
    "section1": {
      "title": "Doctor Production",
      "value": "$0",
      "dailyGoal": {
        "title": "Yearly Goal",
        "value": "$0",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Above Goal",
        "value": "$0 (0%)",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "previousWTD": {
        "title": "From Previous WTD",
        "value": "$0 (0%)",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "chart": {
        "title": "Total Visit",
        "options": {
          "chart": {
            "type": "donut",
            "height": 280,
            "width": 280
          },
          "colors": [
            "#ff9101",
          ],
          "labels": [
            "No Data",
          ],
          "plotOptions": {
            "pie": {
              "expandOnClick": false,
              "donut": {
                "labels": {
                  "show": true,
                  "name": {
                    "show": false,
                    "fontSize": "16px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "value": {
                    "fontSize": "20px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "total": {
                    "show": true,
                    "label": "Total"
                  }
                }
              }
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "width": 0
          },
          "tooltip": {
            "enabled": true
          },
          "legend": {
            "show": false
          },
          "responsive": [
            {
              "breakpoint": 1600,
              "options": {
                "chart": {
                  "width": 215,
                  "height": 215
                },
                "stroke": {
                  "width": 0
                }
              }
            },
            {
              "breakpoint": 567,
              "options": {
                "chart": {
                  "width": 210,
                  "height": 210
                },
                "stroke": {
                  "width": 0
                }
              }
            }
          ]
        },
        "series": [
          0
        ]
      }
    },
    "section2": {
      "title": "Treatment Acceptance",
      "value": "0",
      "dailyGoal": {
        "title": "Yearly Goal",
        "value": "0.00%",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Below Goal",
        "value": "$0 (0.00%)",
        "icon": "arrow-down",
        "iconClass": "red-txt"
      },
      "previousWTD": {
        "title": "From Previous WTD",
        "value": "$0 (0%)",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "chart": {
        "title": null,
        "options": {
          "chart": {
            "type": "donut",
            "height": 280,
            "width": 280
          },
          "colors": [
            "#ff9101"
          ],
          "labels": [
            "No Data"
          ],
          "plotOptions": {
            "pie": {
              "expandOnClick": false,
              "donut": {
                "labels": {
                  "show": true,
                  "name": {
                    "show": false,
                    "fontSize": "16px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "value": {
                    "fontSize": "20px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "total": {
                    "show": true,
                    "label": "Total"
                  }
                }
              }
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "width": 0
          },
          "tooltip": {
            "enabled": true,
            "value": {}
          },
          "legend": {
            "show": false
          },
          "responsive": [
            {
              "breakpoint": 1600,
              "options": {
                "chart": {
                  "width": 215,
                  "height": 215
                },
                "stroke": {
                  "width": 0
                }
              }
            },
            {
              "breakpoint": 567,
              "options": {
                "chart": {
                  "width": 210,
                  "height": 210
                },
                "stroke": {
                  "width": 0
                }
              }
            }
          ]
        },
        "series": [
          0
        ],
        "doctorList": [

        ]
      }
    },
    "section3": {
      "title": "Production Per Visit",
      "value": "$0",
      "dailyGoal": {
        "title": "Yearly Goal",
        "value": "$0",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Above Goal",
        "value": "$0 (0%)",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "previousWTD": {
        "title": "From Previous WTD",
        "value": "$0 (0%)",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      }
    }
  };
  const [doctorSections, setDoctorSections] = useState(dummyDoctor);


  const dummyHygiene = {
    "section1": {
      "title": "Hygiene Production",
      "value": "0",
      "dailyGoal": {
        "title": "Daily Goal",
        "value": "0",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Above Goal",
        "value": "0",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "previousWTD": {
        "title": "From Previous WTD",
        "value": "0",
        "icon": "arrow-down",
        "iconClass": "red-txt"
      },
      "chart": {
        "title": "Total Visit",
        "options": {
          "chart": {
            "type": "donut",
            "height": 280,
            "width": 280
          },
          "colors": [
            "#ff9101"
          ],
          "labels": [
            "No Data"
          ],
          "plotOptions": {
            "pie": {
              "expandOnClick": false,
              "donut": {
                "labels": {
                  "show": true,
                  "name": {
                    "show": false,
                    "fontSize": "16px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "value": {
                    "fontSize": "20px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "total": {
                    "show": true,
                    "label": "Total"
                  }
                }
              }
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "width": 0
          },
          "tooltip": {
            "enabled": true
          },
          "legend": {
            "show": false
          },
          "responsive": [
            {
              "breakpoint": 1600,
              "options": {
                "chart": {
                  "width": 215,
                  "height": 215
                },
                "stroke": {
                  "width": 0
                }
              }
            },
            {
              "breakpoint": 567,
              "options": {
                "chart": {
                  "width": 210,
                  "height": 210
                },
                "stroke": {
                  "width": 0
                }
              }
            }
          ]
        },
        "series": [
          0
        ]
      }
    },
    "section2": {
      "title": "Reappointment Rate",
      "value": "0",
      "dailyGoal": {
        "title": "Goal",
        "value": "0",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Above Goal",
        "value": "0",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "previousWTD": {
        "title": "From Previous WTD",
        "value": "0",
        "icon": "arrow-down",
        "iconClass": "red-txt"
      },
      "chart": {
        "title": null,
        "options": {
          "chart": {
            "type": "donut",
            "height": 280,
            "width": 280
          },
          "colors": [
            "#ff9101"
          ],
          "labels": [
            "No Data"
          ],
          "plotOptions": {
            "pie": {
              "expandOnClick": false,
              "donut": {
                "labels": {
                  "show": true,
                  "name": {
                    "show": false,
                    "fontSize": "16px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "value": {
                    "fontSize": "20px",
                    "fontFamily": "sans-serif",
                    "fontWeight": "800"
                  },
                  "total": {
                    "show": true,
                    "label": "Total"
                  }
                }
              }
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "width": 0
          },
          "tooltip": {
            "enabled": true
          },
          "legend": {
            "show": false
          },
          "responsive": [
            {
              "breakpoint": 1600,
              "options": {
                "chart": {
                  "width": 215,
                  "height": 215
                },
                "stroke": {
                  "width": 0
                }
              }
            },
            {
              "breakpoint": 567,
              "options": {
                "chart": {
                  "width": 210,
                  "height": 210
                },
                "stroke": {
                  "width": 0
                }
              }
            }
          ]
        },
        "series": [
          0
        ],
        "doctorList": []
      }
    },
    "section3": {
      "title": "Production Per Visit",
      "value": "0",
      "dailyGoal": {
        "title": "Goal",
        "value": "0",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Below Goal",
        "value": "0",
        "icon": "arrow-up",
        "iconClass": "green-txt"
      },
      "previousWTD": {
        "title": "From Previous WTD",
        "value": "0",
        "icon": "arrow-down",
        "iconClass": "red-txt"
      }
    }
  };
  const [hygieneSections, setHygieneSections] = useState(dummyHygiene);
  const dummyFrontOffice = {
    "section1": {
      "title": "Reactivated Patients",
      "value": "0",
      "dailyGoal": {
        "title": "Yearly Goal",
        "value": "0.00%",
        "icon": "",
        "iconClass": ""
      },
      "aboveGoal": {
        "title": "Below Goal",
        "value": "$0 (0.00%)",
        "icon": "arrow-down",
        "iconClass": "red-txt"
      },
      "scheduleGoal": {
        "title": "Scheduled Production",
        "value": "$0",
        "icon": "",
        "iconClass": ""
      },
      "chart": {
        "title": null,
        "options": [],
        "series": []
      }
    },
    "section2": {
      "title": "Patients Not Scheduled",
      "value": "0",
      "unscheduledProd": {
        "title": "Unscheduled Production",
        "value": "$0",
        "icon": "",
        "iconClass": ""
      },
      "chart": {
        "title": null,
        "options": {
          "chart": {
            "type": "donut",
            "height": 280,
            "width": 280
          },
          "colors": [
            "#ff9101",
          ],
          "labels": [
            "No Data"
          ],
          "plotOptions": {
            "pie": {
              "expandOnClick": false,
              "donut": {
                "labels": {
                  "show": true,
                  "name": {
                    "show": false,
                    "fontSize": "16px",
                    "fontFamily": "\"Muli\", sans-serif",
                    "fontWeight": "800"
                  },
                  "value": {
                    "fontSize": "20px",
                    "fontFamily": "\"Muli\", sans-serif",
                    "fontWeight": "800"
                  },
                  "total": {
                    "show": true,
                    "label": "Total"
                  }
                }
              }
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "width": 0
          },
          "tooltip": {
            "enabled": true
          },
          "legend": {
            "show": false
          },
          "responsive": [
            {
              "breakpoint": 1600,
              "options": {
                "chart": {
                  "width": 215,
                  "height": 215
                },
                "stroke": {
                  "width": 0
                }
              }
            },
            {
              "breakpoint": 567,
              "options": {
                "chart": {
                  "width": 210,
                  "height": 210
                },
                "stroke": {
                  "width": 0
                }
              }
            }
          ]
        },
        "series": [
          0
        ],
        "doctorList": [

        ]
      }
    },
    "section3": {
      "title": "Total Unscheduled Active Patients",
      "value": "0",
      "patients": {
        "title": "Value",
        "value": "$0",
        "icon": "",
        "iconClass": ""
      },
      "chart": {
        "title": null,
        "options": {
          "chart": {
            "type": "donut",
            "height": 280,
            "width": 280
          },
          "colors": [
            "#ff9101"
          ],
          "labels": [
            "No Data"
          ],
          "plotOptions": {
            "pie": {
              "expandOnClick": false,
              "donut": {
                "labels": {
                  "show": true,
                  "name": {
                    "show": false,
                    "fontSize": "16px",
                    "fontFamily": "\"Muli\", sans-serif",
                    "fontWeight": "800"
                  },
                  "value": {
                    "fontSize": "20px",
                    "fontFamily": "\"Muli\", sans-serif",
                    "fontWeight": "800"
                  },
                  "total": {
                    "show": true,
                    "label": "Total"
                  }
                }
              }
            }
          },
          "dataLabels": {
            "enabled": false
          },
          "stroke": {
            "width": 0
          },
          "tooltip": {
            "enabled": true
          },
          "legend": {
            "show": false
          },
          "responsive": [
            {
              "breakpoint": 1600,
              "options": {
                "chart": {
                  "width": 215,
                  "height": 215
                },
                "stroke": {
                  "width": 0
                }
              }
            },
            {
              "breakpoint": 567,
              "options": {
                "chart": {
                  "width": 210,
                  "height": 210
                },
                "stroke": {
                  "width": 0
                }
              }
            }
          ]
        },
        "series": [
          0
        ]
      }
    }
  };
  const [frontOfficeSections, setFrontOfficeSections] =
    useState(dummyFrontOffice);
  const dummyReferal = {
    section1: {
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: [],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
    section2: {
      chart: {
        title: null,
        options: {
          chart: {
            type: "donut",
            height: 280,
            width: 280,
          },
          colors: ["#ff9101"],
          labels: [],
          plotOptions: {
            pie: {
              expandOnClick: false,
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                    fontSize: "16px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  value: {
                    fontSize: "20px",
                    fontFamily: '"Muli", sans-serif',
                    fontWeight: "800",
                  },
                  total: {
                    show: true,
                    label: "Total",
                  },
                },
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 0,
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 1600,
              options: {
                chart: {
                  width: 215,
                  height: 215,
                },
                stroke: {
                  width: 0,
                },
              },
            },
            {
              breakpoint: 567,
              options: {
                chart: {
                  width: 210,
                  height: 210,
                },
                stroke: {
                  width: 0,
                },
              },
            },
          ],
        },
        series: [0],
        doctorList: [],
      },
    },
  };
  const [referralSourceSections, setReferralSourceSections] =
    useState(dummyReferal);

  const mixChartOptionsConfig = {
    colors: ["#8fd5bd", "#d4daf1"],
    chart: {
      height: 350,
      offsetX: 0,
      type: "line",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [5, 1],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },

    fill: {
      opacity: [1, 0.65],
      type: ["solid", "gradient"],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.75,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: [],
    markers: {
      size: 0,
    },
    xaxis: {
      labels: {
        offsetX: 1,
      },
    },
    yaxis: {
      show: false,
      title: {
        text: "Patients",
        align: "right",
        style: {
          colors: ["#040d14"],
          fontSize: "14px",
          fontFamily: "Helvetica, Arial, sans-serif",
          fontWeight: 600,
          cssClass: "apexcharts-yaxis-label",
        },
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return y.toFixed(0) + " Patients";
          }
          return y;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 0,
    },
  };

  const [showDatePicker, setShowDatePicker] = useState(false);

  const chartColors = [
    { colorClass: "yellow-label", value: "#ff9101" },
    { colorClass: "blue-label", value: "#01458c" },
    { colorClass: "green-label", value: "#00b388" },
    { colorClass: "red-label", value: "#ff0000" },
    { colorClass: "purple-label", value: "#7566a0" },
    { colorClass: "limeblue-label", value: "#00aef0" },
  ];

  const [showDashboardSummaryLoading, setShowDashboardSummaryLoading] =
    useState(true);
  const [showPatientChartLoading, setShowPatientChartLoading] = useState(true);
  const [showDoctorSectionLoading, setShowDoctorSectionLoading] =
    useState(true);
  const [showHygieneSectionLoading, setShowHygieneSectionLoading] =
    useState(true);
  const [showFrontOfficeSectionLoading, setShowFrontOfficeSectionLoading] =
    useState(true);
  const [
    showReferralSourceSectionLoading,
    setShowReferralSourceSectionLoading,
  ] = useState(true);

  const [showSectionLoading, setShowSectionLoading] = useState(true);

  const [fromDate, setFromDate] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [toDate, setToDate] = useState(false);
  const [toDateError, setToDateError] = useState(false);

  const dummyPatientChartData = {
    totalPatients: null,
    activePatients: null,
    inactivePatients: null,
    chart: {
      series: [
        {
          name: "Active",
          type: "line",
          data: [],
        },
        {
          name: "Inactive",
          type: "area",
          data: [],
        },
      ],
      options: { ...mixChartOptionsConfig },
    },
  };
  const [patientChartData, setPatientChartData] = useState(
    dummyPatientChartData
  );

  const executeSelectedOption = (event) => {
    event.preventDefault();
    if (
      event.target.value != null &&
      event.target.value != undefined &&
      event.target.value != ""
    ) {
      setChartType(event.target.value);
    }
  };

  const showAllLoaders = () => {
    setShowDashboardSummaryLoading(true);
    setShowPatientChartLoading(true);
    setShowDoctorSectionLoading(true);
    setShowHygieneSectionLoading(true);
    setShowFrontOfficeSectionLoading(true);
    setShowReferralSourceSectionLoading(true);
  };

  const getPatientChart = (data) => {
    setShowPatientChartLoading(true);
    setPatientChartData(dummyPatientChartData);
    Api.getPatientChart(data)
      .then((response) => {
        setShowPatientChartLoading(false);
        if (response.data && response.data.Result) {
          let data = {
            totalPatients: response.data.Result.TotalPatient,
            activePatients: response.data.Result.TotalActivePatient,
            inactivePatients: response.data.Result.TotalInActivePatient,
            chart: {
              series: [
                {
                  name: "Active",
                  type: "line",
                  data: [],
                },
                {
                  name: "Inactive",
                  type: "area",
                  data: [],
                },
              ],
              options: { ...mixChartOptionsConfig },
            },
          };
          for (let i = 0; i < response.data.Result.List.length; i++) {
            const element = response.data.Result.List[i];
            data.chart.options.labels.push(element.LabelName);
            for (let j = 0; j < element.Value.length; j++) {
              const item = element.Value[j];
              if (item.Name == "Active") {
                data.chart.series[0].data.push(parseInt(item.Value));
              }
              if (item.Name == "InActive") {
                data.chart.series[1].data.push(parseInt(item.Value));
              }
            }
          }
          setPatientChartData(data);
        }
      })
      .catch((e) => {
        setShowPatientChartLoading(false);
      });
  };

  const getDoctorWidgetSections = (data) => {
    setShowDoctorSectionLoading(true);
    setDoctorSections(dummyDoctor);
    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowDoctorSectionLoading(false);
        if (
          response.data &&
          response.data.Result &&
          response.data.Result.length > 0
        ) {
          let doctorSection = {
            section1: null,
            section2: null,
            section3: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            // if (element.Title == "Doctor Production") {
            if (element.OrderBy == 1) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Daily Goal") {
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Above Goal") {
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  // data.aboveGoal.value = widgetBox.Value;
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (<>{widgetBox.Value}<br />({per})</>);
                  // data.previousWTD.value = widgetBox.Value;
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 4) {
                  data.chart.title = widgetBox.Title;
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let i = 0; i < widgetBox.Value.length; i++) {
                    const item = widgetBox.Value[i];
                    const colorCode = chartColors[i % 6];
                    const number = (parseInt(item.Value) == NaN) ? 0 : parseInt(item.Value);
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              doctorSection.section1 = data;
            } else if (element.OrderBy == 2) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Goal") {
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  // data.aboveGoal.value = widgetBox.Value;
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (<>{widgetBox.Value}<br />({per})</>);
                  // data.previousWTD.value = widgetBox.Value;
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 4) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (
                                  "$" +
                                  (Math.round(val * 100) / 100).toLocaleString()
                                );
                              },
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  "$" +
                                  (
                                    Math.round(
                                      w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b;
                                      }, 0) * 100
                                    ) / 100
                                  ).toLocaleString()
                                );
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                      value: {
                        formatter: function (value) {
                          return "$" + value;
                        },
                      },
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={`blue-label ${colorCode.colorClass}`}>
                        {item.DoctorName}
                        <span>{item.StringValue}</span>
                      </li>
                    );
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              doctorSection.section2 = data;
            } else if (element.OrderBy == 3) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                // chart: {
                //   title:null,
                //   options:[],
                //   series:[],
                //   doctorList:[]
                // }
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  // data.aboveGoal.value = widgetBox.Value;
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                if (widgetBox.OrderBy == 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (<>{widgetBox.Value}<br />({per})</>);
                  // data.previousWTD.value = widgetBox.Value;
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
              }
              doctorSection.section3 = data;
            }
          }
          setDoctorSections(doctorSection);
        }
      })
      .catch((e) => {
        setShowDoctorSectionLoading(false);
      });
  };
  const getHygieneWidgetSections = (data) => {
    setShowHygieneSectionLoading(true);
    setHygieneSections(dummyHygiene);
    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowHygieneSectionLoading(false);
        if (
          response.data &&
          response.data.Result &&
          response.data.Result.length > 0
        ) {
          let hygieneSection = {
            section1: null,
            section2: null,
            section3: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            if (element.OrderBy == 1) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Daily Goal") {
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Above Goal") {
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "From Previous WTD") {
                if (widgetBox.OrderBy == 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Total Visit") {
                if (widgetBox.OrderBy == 4) {
                  data.chart.title = widgetBox.Title;
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let i = 0; i < widgetBox.Value.length; i++) {
                    const item = widgetBox.Value[i];
                    const colorCode = chartColors[i % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              hygieneSection.section1 = data;
            } else if (element.OrderBy == 2) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Goal") {
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Above Goal") {
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "From Previous WTD") {
                if (widgetBox.OrderBy == 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "") {
                if (widgetBox.OrderBy == 4) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={`blue-label ${colorCode.colorClass}`}>
                        {item.DoctorName}
                        <span>{item.StringValue}</span>
                      </li>
                    );
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              hygieneSection.section2 = data;
            } else if (element.OrderBy == 3) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                previousWTD: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Goal") {
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Below Goal") {
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "From Previous WTD") {
                if (widgetBox.OrderBy == 2) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.previousWTD.title = widgetBox.Title;
                  data.previousWTD.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.previousWTD.icon = widgetBox.IconClass;
                  data.previousWTD.iconClass = widgetBox.IconColorClass;
                }
              }
              hygieneSection.section3 = data;
            }
          }
          setHygieneSections(hygieneSection);
        }
      })
      .catch((e) => {
        setShowHygieneSectionLoading(false);
      });
  };
  const getFrontOfficeWidgetSections = (data) => {
    setShowFrontOfficeSectionLoading(true);
    setFrontOfficeSections(dummyFrontOffice);
    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowFrontOfficeSectionLoading(false);
        if (
          response.data &&
          response.data.Result &&
          response.data.Result.length > 0
        ) {
          let frontOffice = {
            section1: null,
            section2: null,
            section3: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            // if (element.Title == "Reactivated Patients") {
            if (element.OrderBy == 1) {
              let data = {
                title: element.Title,
                value: element.Value,
                dailyGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                aboveGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                scheduleGoal: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Reactivated Patients Daily Goal") {
                if (widgetBox.OrderBy == 1) {
                  data.dailyGoal.title = widgetBox.Title;
                  data.dailyGoal.value = widgetBox.Value;
                  data.dailyGoal.icon = widgetBox.IconClass;
                  data.dailyGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Below Goal") {
                if (widgetBox.OrderBy == 3) {
                  let per =
                    widgetBox.Percentage && widgetBox.Percentage.length > 0
                      ? widgetBox.Percentage
                      : "0%";
                  data.aboveGoal.title = widgetBox.Title;
                  data.aboveGoal.value = (<>{widgetBox.Value}<br />({per})</>);
                  data.aboveGoal.icon = widgetBox.IconClass;
                  data.aboveGoal.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "Scheduled Production") {
                if (widgetBox.OrderBy == 2) {
                  data.scheduleGoal.title = widgetBox.Title;
                  data.scheduleGoal.value = widgetBox.Value;
                  data.scheduleGoal.icon = widgetBox.IconClass;
                  data.scheduleGoal.iconClass = widgetBox.IconColorClass;
                }
              }
              frontOffice.section1 = data;
            } else if (element.OrderBy == 2) {
              let data = {
                title: element.Title,
                value: element.Value,
                unscheduledProd: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Unscheduled Production") {
                if (widgetBox.OrderBy == 1) {
                  data.unscheduledProd.title = widgetBox.Title;
                  data.unscheduledProd.value = widgetBox.Value;
                  data.unscheduledProd.icon = widgetBox.IconClass;
                  data.unscheduledProd.iconClass = widgetBox.IconColorClass;
                }

                // if (widgetBox.Title == "") {
                if (widgetBox.OrderBy == 2) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={`blue-label ${colorCode.colorClass}`}>
                        {item.DoctorName}
                        <span>{item.StringValue}</span>
                      </li>
                    );
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              frontOffice.section2 = data;
              // } else if (element.Title == "Total Unscheduled Active Patients") {
            } else if (element.OrderBy == 3) {
              let data = {
                title: element.Title,
                value: element.Value,
                patients: {
                  title: null,
                  value: null,
                  icon: null,
                  iconClass: null,
                },
                chart: {
                  title: null,
                  options: [],
                  series: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                // if (widgetBox.Title == "Value") {
                if (widgetBox.OrderBy == 1) {
                  data.patients.title = widgetBox.Title;
                  data.patients.value = widgetBox.Value;
                  data.patients.icon = widgetBox.IconClass;
                  data.patients.iconClass = widgetBox.IconColorClass;
                }
                // if (widgetBox.Title == "") {
                if (widgetBox.OrderBy == 2) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let i = 0; i < widgetBox.Value.length; i++) {
                    const item = widgetBox.Value[i];
                    const colorCode = chartColors[i % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              frontOffice.section3 = data;
            }
          }
          setFrontOfficeSections(frontOffice);
        }
      })
      .catch((e) => {
        setShowFrontOfficeSectionLoading(false);
      });
  };
  const getReferralSourceWidgetSections = (data) => {
    setShowReferralSourceSectionLoading(true);
    setReferralSourceSections(dummyReferal);
    Api.getWidgetsDetails(data)
      .then((response) => {
        setShowReferralSourceSectionLoading(false);
        if (
          response.data &&
          response.data.Result &&
          response.data.Result.length > 0
        ) {
          let referralSource = {
            section1: null,
            section2: null,
          };
          for (let i = 0; i < response.data.Result.length; i++) {
            const element = response.data.Result[i];
            if (element.OrderBy == 1) {
              let data = {
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy == 1) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  Math.round(
                                    w.globals.seriesTotals.reduce((a, b) => {
                                      return a + b;
                                    }, 0) * 100
                                  ) / 100
                                ).toLocaleString();
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={colorCode.colorClass}>
                        {item.DoctorName}
                      </li>
                    );
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              referralSource.section1 = data;
              // } else if (element.Title == "Production By Referral Source") {
            } else if (element.OrderBy == 2) {
              let data = {
                chart: {
                  title: null,
                  options: [],
                  series: [],
                  doctorList: [],
                },
              };
              for (let i = 0; i < element.WidgetBox.length; i++) {
                const widgetBox = element.WidgetBox[i];
                if (widgetBox.OrderBy == 1) {
                  data.chart.options = {
                    chart: {
                      type: "donut",
                      height: 280,
                      width: 280,
                    },
                    colors: [],
                    labels: [],
                    plotOptions: {
                      pie: {
                        expandOnClick: false,
                        donut: {
                          labels: {
                            show: true,
                            name: {
                              show: false,
                              fontSize: "16px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                            },
                            value: {
                              fontSize: "20px",
                              fontFamily: '"Muli", sans-serif',
                              fontWeight: "800",
                              formatter: function (val) {
                                return "$" + (Math.round(val * 100) / 100).toLocaleString();
                              }
                            },
                            total: {
                              show: true,
                              label: "Total",
                              formatter: function (w) {
                                return (
                                  "$" +
                                  (
                                    Math.round(
                                      w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b;
                                      }, 0) * 100
                                    ) / 100
                                  ).toLocaleString()
                                );
                              },
                            },
                          },
                        },
                      },
                    },
                    dataLabels: {
                      enabled: false,
                    },
                    stroke: {
                      width: 0,
                    },
                    tooltip: {
                      enabled: true,
                    },
                    legend: {
                      show: false,
                    },
                    responsive: [
                      {
                        breakpoint: 1600,
                        options: {
                          chart: {
                            width: 215,
                            height: 215,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                      {
                        breakpoint: 567,
                        options: {
                          chart: {
                            width: 210,
                            height: 210,
                          },
                          stroke: {
                            width: 0,
                          },
                        },
                      },
                    ],
                  };
                  data.chart.options.colors = [];
                  var totalSum = 0;
                  for (let j = 0; j < widgetBox.Value.length; j++) {
                    const item = widgetBox.Value[j];
                    const colorCode = chartColors[j % 6];
                    totalSum = totalSum + parseInt(item.Value);
                    data.chart.options.colors.push(colorCode.value);
                    data.chart.series.push(
                      isNaN(parseInt(item.Value)) ? 0 : parseInt(item.Value)
                    );
                    data.chart.options.labels.push(item.DoctorName);
                    data.chart.doctorList.push(
                      <li className={colorCode.colorClass}>
                        {item.DoctorName}
                      </li>
                    );
                  }
                  if (totalSum == 0) {
                    data.chart.series = [];
                  }
                }
              }
              referralSource.section2 = data;
            }
          }
          setReferralSourceSections(referralSource);
        }
        // getPatientChart(data)
      })
      .catch((e) => {
        setShowReferralSourceSectionLoading(false);
        // getPatientChart(data);
      });
  };

  const getWidgetSectionsList = (dataObj) => {
    Api.getWidgetSections()
      .then((response) => {
        var myData = {
          doctor: null,
          hygiene: null,
          frontOffice: null,
          referralSource: null,
        };

        for (let index = 0; index < response.data.Result.length; index++) {
          const element = response.data.Result[index];
          switch (element.OrderBy) {
            case 1:
              myData.doctor = element.Id;
              break;
            case 2:
              myData.hygiene = element.Id;
              break;
            case 3:
              myData.frontOffice = element.Id;
              break;
            case 4:
              myData.referralSource = element.Id;
              break;
            default:
              break;
          }
        }
        localStorage.setItem("sectionList", JSON.stringify(myData));
        setDashboardSections(myData);
      })
      .catch((error) => {
        setShowDoctorSectionLoading(false);
        setShowHygieneSectionLoading(false);
        setShowFrontOfficeSectionLoading(false);
        setShowReferralSourceSectionLoading(false);
      });
  };

  const getWidgetSections = (data) => {
      getDoctorWidgetSections({ ...data, SectionId: dashboardSections.doctor });
      getHygieneWidgetSections({
        ...data,
        SectionId: dashboardSections.hygiene,
      });
      getFrontOfficeWidgetSections({
        ...data,
        SectionId: dashboardSections.frontOffice,
      });
      getReferralSourceWidgetSections({
        ...data,
        SectionId: dashboardSections.referralSource,
      });    
  };

  const setChartType = (id) => {
    let obj = { ...dashboardSummaryModel };
    obj.ChartType = id == 8 ? null : id;
    obj.AccountId = user.AccountId;
    obj.UserId = obj.UserId == 0 ? null : obj.UserId;
    setDashboardSummaryModel(obj);
    if (id != 8) {
      showAllLoaders();
      getDashboardSummary(obj);
      getWidgetSections(obj);
      getPatientChart(obj);
      setShowDatePicker(false);
    } else {
      setShowDatePicker(true);
    }
  };

  const handelApplyDateFilter = (event) => {
    event.preventDefault();
    let obj = { ...dashboardSummaryModel };
    obj.ChartType = null;
    obj.StartDate = null;
    obj.EndDate = null;
    obj.AccountId = user.AccountId;
    if (fromDate) {
      obj.StartDate = new Date(fromDate).toISOString();
    } else {
      setFromDateError("From Date is required.");
    }

    if (toDate) {
      obj.EndDate = new Date(toDate).toISOString();
    } else {
      setToDateError("To Date is required.");
    }

    if (obj.StartDate && obj.EndDate) {
      if (new Date(fromDate) > new Date(toDate)) {
        setFromDateError("Please select less date then To Date");
      } else if (new Date(toDate) < new Date(fromDate)) {
        setToDateError("Please select greater date then From Date.");
      } else {
        showAllLoaders();
        getDashboardSummary(obj);
        getWidgetSections(obj);
        getPatientChart(obj);
      }
    }

    setDashboardSummaryModel(obj);
  };

  const setUserId = (id) => {
    let obj = { ...dashboardSummaryModel };
    obj.AccountId = user.AccountId;
    obj.UserId = id == 0 ? null : id;
    setDashboardSummaryModel(obj);
    showAllLoaders();
    getDashboardSummary(obj);
    getWidgetSections(obj);
    getPatientChart(obj);
  };

  const getDashboardSummary = (model) => {
    if (model.AccountId > 0) {
      setShowDashboardSummaryLoading(true);
      setDashboardSummaryResModel(dummySummaryData);
      Api.getDashboardSummary(model)
        .then((res) => {
          setShowDashboardSummaryLoading(false);
          var newData = {
            TotalProduction: {
              value: 0,
              imageUrl: "",
              title: "Total Production",
            },
            TotalCollections: {
              value: 0,
              imageUrl: "",
              title: "Total Collections",
            },
            ActivePatients: {
              value: 0,
              imageUrl: "",
              title: "Total Unscheduled Active Patients",
            },
            NewPatients: {
              value: 0,
              imageUrl: "",
              title: "New Patients",
            },
          };
          for (let index = 0; index < res.data.Result.length; index++) {
            const item = res.data.Result[index];
            // if (item.Value.indexOf("$") < 0) {
            //   item.Value = Math.round(parseInt(item.Value.replaceAll(",", "")));
            // } else {
            //   item.Value = Math.round(
            //     parseInt(item.Value.substring(1).replaceAll(",", ""))
            //   );
            // }
            if (item.Value) {
              item.Value = item.Value.split(".")[0];
            }
            switch (item.SectionId) {
              case 14:
                newData.TotalCollections = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                };
                break;
              case 13:
                newData.TotalProduction = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                };
                break;
              case 8:
                newData.ActivePatients = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                };
                break;
              case 15:
                newData.NewPatients = {
                  value: item.Value,
                  imageUrl: item.ImageURL,
                  title: item.Title,
                };
                break;
              default:
                break;
            }
          }
          setDashboardSummaryResModel(newData);

          // getWidgetSectionsList(model);
          // getPatientChart(model);
        })
        .catch((error) => {
          setShowDashboardSummaryLoading(false);

          // getPatientChart(model);
        });
    }
  };

  const handelDateChange = (event) => {
    event.preventDefault();
    event.persist();
    let { name, value } = event.target;
    let errorValue = "";
    setFromDateError("");
    setToDateError("");
    switch (name) {
      case "fromDate":
        if (value.length == 0) {
          errorValue = "From Date is required.";
        } else if (toDate != "" && new Date(value) > new Date(toDate)) {
          errorValue = "Please select less date then To Date";
        } else {
          errorValue = "";
        }
        setFromDate(value);
        setFromDateError(errorValue);
        break;
      case "toDate":
        if (value.length == 0) {
          errorValue = "To Date is required.";
        } else if (fromDate != "" && new Date(value) < new Date(fromDate)) {
          errorValue = "Please select greater date then From Date";
        } else {
          errorValue = "";
        }
        setToDate(value);
        setToDateError(errorValue);
        break;
      default:
        break;
    }
  };

  const getProvider = (AccountId) => {
    if (AccountId > 0) {
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      Api.getProvider(AccountId)
        .then((res) => {
          setProvider(res.data.Result);
          getDashboardSummary(obj);
          getPatientChart(obj);
          let sectionList = JSON.parse(localStorage.getItem("sectionList"));
          if (sectionList) {
            var myData = {
              doctor: null,
              hygiene: null,
              frontOffice: null,
              referralSource: null,
            };
            myData = { ...myData, ...sectionList };
            setDashboardSections(myData);
          } else {
            setTimeout(() => {
              getWidgetSectionsList(obj);
            }, 1000);
          }
        })
        .catch((error) => {
          getDashboardSummary(obj);
          getPatientChart(obj);
          let sectionList = JSON.parse(localStorage.getItem("sectionList"));
          if (sectionList) {
            var myData = {
              doctor: null,
              hygiene: null,
              frontOffice: null,
              referralSource: null,
            };
            myData = { ...myData, ...sectionList };
            setDashboardSections(myData);
          } else {
            setTimeout(() => {
              getWidgetSectionsList(obj);
            }, 1000);
          }
        });
    }
  };

  useEffect(() => {
    let userInfo = JSON.parse(localStorage.getItem("userData"));
    if (userInfo != null && userInfo.UserId && userInfo.AccountId > 0) {
      setUser(userInfo);
    }
    if (user.AccountId > 0) {
      showAllLoaders();
      getProvider(user.AccountId);
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      // getWidgetSectionsList(obj);
      // getDashboardSummary(obj);
      // getPatientChart(obj);
    }
  }, [user.AccountId]);

  useEffect(() => {
    if (
      dashboardSections.doctor != null ||
      dashboardSections.hygiene != null ||
      dashboardSections.frontOffice != null ||
      dashboardSections.referralSource != null
    ) {
      let obj = { ...dashboardSummaryModel };
      obj.AccountId = user.AccountId;
      obj.UserId = null;
      getWidgetSections(obj);
    }
  }, [dashboardSections]);

  return (
    <div>
      {/* <AppNavbar provider={provider} setUserId={setUserId} /> */}
      <AppNavbar
        provider={provider}
        setUserId={setUserId}
        setChartType={setChartType}
        dropDownChange={executeSelectedOption}
        ChartType={dashboardSummaryModel.ChartType}
      />
      <div id="sub-header ">
        {showDatePicker ? (
          <div className="d-flex  py-4  justify-content-center  align-items-end border-top flex-wrap date-filter">
            <div className="filter-ctrl">
              <label>From Date</label>
              <input
                type="date"
                className="form-control date-select"
                name="fromDate"
                value={fromDate}
                max={new Date().toISOString().slice(0, 10)}
                onChange={handelDateChange}
              />
              <div className="err-message">{fromDateError}</div>
            </div>

            {/* <div className="err-message">NoDate</div> */}
            <div className="filter-ctrl">
              <label>To Date</label>
              <input
                type="date"
                className="form-control date-select"
                name="email"
                name="toDate"
                value={toDate}
                onChange={handelDateChange}
                max={new Date().toISOString().slice(0, 10)}
              />
              <div className="err-message">{toDateError}</div>
            </div>
            {/* <div className="err-message">NoDate</div> */}
            <button
              type="button"
              className="btn btn-sm blue-btn-bg green-btn green-border "
              onClick={handelApplyDateFilter}
              disabled={fromDateError.length != 0 || toDateError.length != 0}
            >
              Apply
            </button>
          </div>
        ) : (
          <div className="d-flex  py-4"></div>
        )}
      </div>
      {/* <!-- cards --> */}
      <div id="blue-card" className="calender-box pt-0">
        <div className="container-fluid">
          <div className="row calender-row">
            <div className="col-xl-5 col-md-12 col-12 padding-r-4 calender-col">
              <div className="row">
                <div className=" col-lg-6  col-md-6 col-sm-6 col-12 card-padding-1 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      {/* <h6>Total Production</h6> */}
                      <h6>{dashboardSummaryResModel.TotalProduction.title}</h6>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>{dashboardSummaryResModel.TotalProduction.value}</p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img
                        src={
                          dashboardSummaryResModel.TotalProduction.imageUrl
                            ? dashboardSummaryResModel.TotalProduction.imageUrl
                            : Card1
                        }
                      />
                      {/* <img src={(dashboardSummaryResModel.length > 0 && dashboardSummaryResModel[0].ImageURL)?dashboardSummaryResModel[0].ImageURL:""} /> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4 pb-2">
                  <div className="card blue-card">
                    <div className="header">
                      {/* <h6>Total Collections</h6> */}
                      <h6>{dashboardSummaryResModel.TotalCollections.title}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>
                          {/* {dashboardSummaryResModel.length > 0
                            ? "$" +
                            Math.round(
                              parseInt(
                                dashboardSummaryResModel[1].Value.substring(
                                  1
                                ).replace(",", "")
                              )
                            )
                            : "$0"} */}
                          {dashboardSummaryResModel.TotalCollections.value}
                        </p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img
                        src={
                          dashboardSummaryResModel.TotalCollections.imageUrl
                            ? dashboardSummaryResModel.TotalCollections.imageUrl
                            : Card2
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-1">
                  <div className="card blue-card">
                    <div className="header">
                      {/* <h6>
                        Unscheduled Active <br />
                        Patients
                      </h6> */}
                      <h6>{dashboardSummaryResModel.ActivePatients.title}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>
                          {/* {dashboardSummaryResModel.length > 0
                            ? Math.round(
                              parseInt(
                                dashboardSummaryResModel[4].Value.replace(
                                  ",",
                                  ""
                                )
                              )
                            )
                            : "0"} */}
                          {dashboardSummaryResModel.ActivePatients.value}
                        </p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img
                        src={
                          dashboardSummaryResModel.ActivePatients.imageUrl
                            ? dashboardSummaryResModel.ActivePatients.imageUrl
                            : Card4
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 card-padding-4">
                  <div className="card blue-card">
                    <div className="header">
                      {/* <h6>New Patients</h6> */}
                      <h6>{dashboardSummaryResModel.NewPatients.title}</h6>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <p>
                          {/* {dashboardSummaryResModel.length > 0
                            ? Math.round(
                              parseInt(
                                dashboardSummaryResModel[2].Value.replace(
                                  ",",
                                  ""
                                )
                              )
                            )
                            : "0"} */}
                          {dashboardSummaryResModel.NewPatients.value}
                        </p>
                      </div>
                    </div>
                    <div className="card-img">
                      <img
                        src={
                          dashboardSummaryResModel.NewPatients.imageUrl
                            ? dashboardSummaryResModel.NewPatients.imageUrl
                            : Card3
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={!showDashboardSummaryLoading ? "d-none" : ""}>
                <AppSectionLoader />
              </div>
            </div>
            <div className="col-xl-7 col-md-12 col-12 padding-l-4 calender-col prod-col main-mixchart">
              <div className="total-patients-list">
                <div className="numberOf-patients-list">
                  <div className="numberOf-patients-txt">Total Patients</div>
                  <div className="numberOf-patients">
                    {patientChartData && patientChartData.totalPatients
                      ? patientChartData.totalPatients
                      : 0}
                  </div>
                </div>
                <div className="numberOf-patients-list">
                  <div className="numberOf-patients-txt">Active Patients</div>
                  <div className="numberOf-patients">
                    {patientChartData && patientChartData.activePatients
                      ? patientChartData.activePatients
                      : 0}
                  </div>
                </div>
                <div className="numberOf-patients-list">
                  <div className="numberOf-patients-txt">Inactive Patients</div>
                  <div className="numberOf-patients">
                    {patientChartData && patientChartData.inactivePatients
                      ? patientChartData.inactivePatients
                      : 0}
                  </div>
                </div>
              </div>
              <div className="white-card pb-0 stack-box  mix-area-chart">
                {/* <h6 className="blue-title-h6 position-absolute">Production</h6> */}
                <Select2
                  className="chart-select"
                  // defaultValue={6}
                  value={dashboardSummaryModel.ChartType}
                  data={[
                    { text: "Days", id: 0 },
                    { text: "Week", id: 2 },
                    { text: "Month", id: 3 },
                    { text: "Year", id: 4 },
                  ]}
                  options={{
                    placeholder: "Select",
                  }}
                  onSelect={executeSelectedOption}
                />
                <Chart
                  id="#chart-stack"
                  options={patientChartData.chart.options}
                  series={patientChartData.chart.series}
                  // type="bar"
                  height={310}
                />
                <div className={!showPatientChartLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- cards --> */}
      <div
        id="chart-data"
        className="historical-doctor card-row doctor-row pb-0 circle-chart"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                Doctor
              </h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row">
                <div className="doctor-production patients-info-box donut-chart-col">
                  {doctorSections && doctorSections.section1 && (
                    <>
                      <div className="patients-info">
                        <h6>{doctorSections.section1.title}</h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {doctorSections.section1.value}
                            </span>
                            <div className="daily-goal right-border">
                              <h6>
                                {doctorSections.section1.dailyGoal.title
                                  ? doctorSections.section1.dailyGoal.title
                                  : ""}
                              </h6>
                              <span className="number">
                                {doctorSections.section1.dailyGoal.value
                                  ? doctorSections.section1.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${doctorSections.section1.previousWTD.iconClass
                                  ? doctorSections.section1.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {/* <i class="fa fa-caret-up"></i> */}
                                <span
                                  className={
                                    doctorSections.section1.previousWTD.icon
                                      ? doctorSections.section1.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${doctorSections.section1.previousWTD.iconClass
                                  ? doctorSections.section1.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {doctorSections.section1.previousWTD.value
                                  ? doctorSections.section1.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                {doctorSections.section1.previousWTD.title
                                  ? doctorSections.section1.previousWTD.title
                                  : ""}
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${doctorSections.section1.aboveGoal.iconClass
                                  ? doctorSections.section1.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {/* <i class="fa fa-caret-up"></i> */}
                                <span
                                  className={
                                    doctorSections.section1.aboveGoal.icon
                                      ? doctorSections.section1.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${doctorSections.section1.aboveGoal.iconClass
                                  ? doctorSections.section1.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {doctorSections.section1.aboveGoal.value
                                  ? doctorSections.section1.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${doctorSections.section1.aboveGoal.iconClass
                                  ? doctorSections.section1.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {doctorSections.section1.aboveGoal.title
                                  ? doctorSections.section1.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6>{doctorSections.section1.chart.title}</h6>
                        <div className="patients-info-map donut-center-value">
                          <div className="app-chart-container">
                            <p className="chart-data">
                              <span>439</span>
                            </p>
                            <div className="inner-container">
                              {doctorSections.section1.chart.series.length >
                              0 ? (
                                <Chart
                                  id="new-5"
                                  options={
                                    doctorSections.section1.chart.options
                                  }
                                  series={doctorSections.section1.chart.series}
                                  type="donut"
                                  height={240}
                                />) : (
                                <span style={{ fontSize: '16px' }}>No Data Found</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box treatment-info-box">
                  {doctorSections && doctorSections.section2 && (
                    <>
                      <div className="patients-info right-border">
                        <h6>{doctorSections.section2.title}</h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {doctorSections.section2.value}
                            </span>
                            <div className="daily-goal  right-border">
                              <h6>
                                {doctorSections.section2.dailyGoal.title
                                  ? doctorSections.section2.dailyGoal.title
                                  : ""}
                              </h6>
                              {/* <span className="number green-txt">65%</span> */}
                              <span
                                className={`number ${doctorSections.section2.aboveGoal.iconClass
                                  ? doctorSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {/* $ */}
                                {doctorSections.section2.dailyGoal.value
                                  ? doctorSections.section2.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${doctorSections.section2.previousWTD.iconClass
                                  ? doctorSections.section2.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    doctorSections.section2.previousWTD.icon
                                      ? doctorSections.section2.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${doctorSections.section2.previousWTD.iconClass
                                  ? doctorSections.section2.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {doctorSections.section2.previousWTD.value
                                  ? doctorSections.section2.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {doctorSections.section2.previousWTD.title
                                    ? doctorSections.section2.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${doctorSections.section2.aboveGoal.iconClass
                                  ? doctorSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    doctorSections.section2.aboveGoal.icon
                                      ? doctorSections.section2.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${doctorSections.section2.aboveGoal.iconClass
                                  ? doctorSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {doctorSections.section2.aboveGoal.value
                                  ? doctorSections.section2.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${doctorSections.section2.aboveGoal.iconClass
                                  ? doctorSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {doctorSections.section2.aboveGoal.title
                                  ? doctorSections.section2.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        {/* <h6>Total Visit</h6> */}
                        <div className="patients-info-map">
                          <div className="flex-fill width-100 pos-relative inner-col">
                            <ul className="donut-label list-unstyled">
                              {/* <li className="orange-label blue-label">
                            Presented
                            <span>$1,568</span>
                          </li>
                          <li className="lightblue-label">
                            Accepted
                            <span>$1,249</span>
                          </li>
                          <li className="limeblue-label">
                            Unscheduled
                            <span>$1,158</span>
                          </li> */}
                              {doctorSections.section2.chart.doctorList}
                            </ul>
                            <div className="app-chart-container">
                              {/* <p className="chart-data">
                            Accepted<span>$1,249</span>
                          </p> */}
                              <div className="inner-container">
                                {/* <div id="donut-chart3" className="donut mt-0"></div> */}
                                {doctorSections.section2.chart.series.length >
                                0 ? (
                                  <Chart
                                    id="#donut-chart3"
                                    className="donut mt-0"
                                    options={
                                      doctorSections.section2.chart.options
                                    }
                                    series={
                                      doctorSections.section2.chart.series
                                    }
                                    type="donut"
                                    height={240}
                                  />) : (
                                  <span style={{ fontSize: '16px' }}>No Data Found</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box no-map">
                  {doctorSections && doctorSections.section3 && (
                    <>
                      <div className="patients-info">
                        <h6>
                          {doctorSections.section3.title
                            ? doctorSections.section3.title
                            : ""}
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {doctorSections.section3.value
                                ? doctorSections.section3.value
                                : ""}
                            </span>
                            <div className="daily-goal  right-border">
                              <h6>
                                {doctorSections.section3.dailyGoal.title
                                  ? doctorSections.section3.dailyGoal.title
                                  : ""}
                              </h6>
                              <span className="number">
                                {doctorSections.section3.dailyGoal.value
                                  ? doctorSections.section3.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${doctorSections.section3.previousWTD.iconClass
                                  ? doctorSections.section3.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    doctorSections.section3.previousWTD.icon
                                      ? doctorSections.section3.previousWTD.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${doctorSections.section3.previousWTD.iconClass
                                  ? doctorSections.section3.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {doctorSections.section3.previousWTD.value
                                  ? doctorSections.section3.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {doctorSections.section3.previousWTD.title
                                    ? doctorSections.section3.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${doctorSections.section3.aboveGoal.iconClass
                                  ? doctorSections.section3.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    doctorSections.section3.aboveGoal.icon
                                      ? doctorSections.section3.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${doctorSections.section3.aboveGoal.iconClass
                                  ? doctorSections.section3.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {doctorSections.section3.aboveGoal.value
                                  ? doctorSections.section3.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${doctorSections.section3.aboveGoal.iconClass
                                  ? doctorSections.section3.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {doctorSections.section3.aboveGoal.title
                                  ? doctorSections.section3.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={!showDoctorSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hygiene */}
      <div
        id="chart-data"
        className="historical-doctor hygiene-row card-row pb-0 circle-chart"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                Hygiene
              </h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row">
                <div className="doctor-production patients-info-box donut-chart-col">
                  {hygieneSections && hygieneSections.section1 && (
                    <>
                      <div className="patients-info">
                        <h6>{hygieneSections.section1.title}</h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {hygieneSections.section1.value}
                            </span>
                            <div className="daily-goal right-border">
                              <h6>
                                {hygieneSections.section1.dailyGoal.title
                                  ? hygieneSections.section1.dailyGoal.title
                                  : ""}
                              </h6>
                              <span className="number">
                                {hygieneSections.section1.dailyGoal.value
                                  ? hygieneSections.section1.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${hygieneSections.section1.previousWTD.iconClass
                                  ? hygieneSections.section1.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {/* <i class="fa fa-caret-up"></i> */}
                                <span
                                  className={
                                    hygieneSections.section1.previousWTD.icon
                                      ? hygieneSections.section1.previousWTD
                                          .icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${hygieneSections.section1.previousWTD.iconClass
                                  ? hygieneSections.section1.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {hygieneSections.section1.previousWTD.value
                                  ? hygieneSections.section1.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                {hygieneSections.section1.previousWTD.title
                                  ? hygieneSections.section1.previousWTD.title
                                  : ""}
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${hygieneSections.section1.aboveGoal.iconClass
                                  ? hygieneSections.section1.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {/* <i class="fa fa-caret-up"></i> */}
                                <span
                                  className={
                                    hygieneSections.section1.aboveGoal.icon
                                      ? hygieneSections.section1.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${hygieneSections.section1.aboveGoal.iconClass
                                  ? hygieneSections.section1.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {hygieneSections.section1.aboveGoal.value
                                  ? hygieneSections.section1.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${hygieneSections.section1.aboveGoal.iconClass
                                  ? hygieneSections.section1.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {hygieneSections.section1.aboveGoal.title
                                  ? hygieneSections.section1.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        <h6>{hygieneSections.section1.chart.title}</h6>
                        <div className="patients-info-map donut-center-value">
                          <div className="app-chart-container">
                            <p className="chart-data">
                              <span>439</span>
                            </p>
                            <div className="inner-container">
                              {hygieneSections.section1.chart.series.length >
                              0 ? (
                                <Chart
                                  id="new-5"
                                  options={
                                    hygieneSections.section1.chart.options
                                  }
                                  series={hygieneSections.section1.chart.series}
                                  type="donut"
                                  height={240}
                                />) : (
                                <span style={{ fontSize: '16px' }}>No Data Found</span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box">
                  {hygieneSections && hygieneSections.section2 && (
                    <>
                      <div className="patients-info right-border">
                        <h6>{hygieneSections.section2.title}</h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {hygieneSections.section2.value}
                            </span>
                            <div className="daily-goal  right-border">
                              <h6>
                                {hygieneSections.section2.dailyGoal.title
                                  ? hygieneSections.section2.dailyGoal.title
                                  : ""}
                              </h6>
                              {/* <span className="number green-txt">65%</span> */}
                              <span
                                className={`number ${hygieneSections.section2.aboveGoal.iconClass
                                  ? hygieneSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {/* $ */}
                                {hygieneSections.section2.dailyGoal.value
                                  ? hygieneSections.section2.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${hygieneSections.section2.previousWTD.iconClass
                                  ? hygieneSections.section2.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section2.previousWTD.icon
                                      ? hygieneSections.section2.previousWTD
                                          .icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${hygieneSections.section2.previousWTD.iconClass
                                  ? hygieneSections.section2.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {hygieneSections.section2.previousWTD.value
                                  ? hygieneSections.section2.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {hygieneSections.section2.previousWTD.title
                                    ? hygieneSections.section2.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${hygieneSections.section2.aboveGoal.iconClass
                                  ? hygieneSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section2.aboveGoal.icon
                                      ? hygieneSections.section2.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${hygieneSections.section2.aboveGoal.iconClass
                                  ? hygieneSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {hygieneSections.section2.aboveGoal.value
                                  ? hygieneSections.section2.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${hygieneSections.section2.aboveGoal.iconClass
                                  ? hygieneSections.section2.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {hygieneSections.section2.aboveGoal.title
                                  ? hygieneSections.section2.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        {/* <h6>Total Visit</h6> */}
                        <div className="patients-info-map">
                          <div className="flex-fill width-100 pos-relative inner-col">
                            <ul className="donut-label list-unstyled">
                              {/* <li className="orange-label blue-label">
                            Presented
                            <span>$1,568</span>
                          </li>
                          <li className="lightblue-label">
                            Accepted
                            <span>$1,249</span>
                          </li>
                          <li className="limeblue-label">
                            Unscheduled
                            <span>$1,158</span>
                          </li> */}
                              {hygieneSections.section2.chart.doctorList}
                            </ul>
                            <div className="app-chart-container">
                              {/* <p className="chart-data">
                            Accepted<span>$1,249</span>
                          </p> */}
                              <div className="inner-container">
                                {/* <div id="donut-chart3" className="donut mt-0"></div> */}
                                {hygieneSections.section2.chart.series.length >
                                0 ? (
                                  <Chart
                                    id="#donut-chart3"
                                    className="donut mt-0"
                                    options={
                                      hygieneSections.section2.chart.options
                                    }
                                    series={
                                      hygieneSections.section2.chart.series
                                    }
                                    type="donut"
                                    height={240}
                                  />) : (
                                  <span style={{ fontSize: '16px' }}>No Data Found</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box no-map">
                  {hygieneSections && hygieneSections.section3 && (
                    <>
                      <div className="patients-info">
                        <h6>
                          {hygieneSections.section3.title
                            ? hygieneSections.section3.title
                            : ""}
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {hygieneSections.section3.value
                                ? hygieneSections.section3.value
                                : ""}
                            </span>
                            <div className="daily-goal  right-border">
                              <h6>
                                {hygieneSections.section3.dailyGoal.title
                                  ? hygieneSections.section3.dailyGoal.title
                                  : ""}
                              </h6>
                              <span className="number">
                                {hygieneSections.section3.dailyGoal.value
                                  ? hygieneSections.section3.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="status-col">
                            <div className="prev-status">
                              <span
                                className={`icon ${hygieneSections.section3.previousWTD.iconClass
                                  ? hygieneSections.section3.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section3.previousWTD.icon
                                      ? hygieneSections.section3.previousWTD
                                          .icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${hygieneSections.section3.previousWTD.iconClass
                                  ? hygieneSections.section3.previousWTD
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {hygieneSections.section3.previousWTD.value
                                  ? hygieneSections.section3.previousWTD.value
                                  : ""}
                              </span>
                              <span className="status-txt">
                                <span className="status-txt">
                                  {hygieneSections.section3.previousWTD.title
                                    ? hygieneSections.section3.previousWTD.title
                                    : ""}
                                </span>
                              </span>
                            </div>
                            <div className="next-status">
                              <span
                                className={`icon ${hygieneSections.section3.aboveGoal.iconClass
                                  ? hygieneSections.section3.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                <span
                                  className={
                                    hygieneSections.section3.aboveGoal.icon
                                      ? hygieneSections.section3.aboveGoal.icon
                                      : ""
                                  }
                                ></span>
                              </span>
                              <span
                                className={`status-value ${hygieneSections.section3.aboveGoal.iconClass
                                  ? hygieneSections.section3.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >

                                {hygieneSections.section3.aboveGoal.value
                                  ? hygieneSections.section3.aboveGoal.value
                                  : ""}
                              </span>
                              <span
                                className={`status-txt ${hygieneSections.section3.aboveGoal.iconClass
                                  ? hygieneSections.section3.aboveGoal
                                    .iconClass
                                  : ""
                                  }`}
                              >
                                {hygieneSections.section3.aboveGoal.title
                                  ? hygieneSections.section3.aboveGoal.title
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <div className="patients-map">
                      <h6>Total Visit</h6>
                      <div className="patients-info-map">
                        <Chart
                            id="new-5"
                            options={state6.options}
                            series={state6.series}
                            type="donut"
                            height={170}
                            width={160}
                          />
                      </div>
                    </div> */}
                </div>
                <div className={!showHygieneSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Hygiene */}

      {/* Font Office */}
      <div
        id="chart-data"
        className="historical-doctor font-office-row card-row pb-0 circle-chart"
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                Front Office
              </h6>
            </div>
            <div className="col-12 pb-3">
              <div className="summary-cards-container patients-info-row ">
                <div className="doctor-production patients-info-box reactivate-patients-box">
                  {frontOfficeSections && frontOfficeSections.section1 && (
                    <>
                      <div className="patients-info">
                        <h6>
                          {frontOfficeSections.section1.title
                            ? frontOfficeSections.section1.title
                            : ""}
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {frontOfficeSections.section1.value
                                ? frontOfficeSections.section1.value
                                : ""}
                            </span>
                            {/* <h6>Reactivated Patients</h6> */}
                            <div className="daily-goal right-border">
                              <h6>
                                {/* Reactivated Patients <br /> Daily Goal */}
                                {frontOfficeSections.section1.dailyGoal.title
                                  ? frontOfficeSections.section1.dailyGoal.title
                                  : ""}
                              </h6>
                              <span className="number">
                                {frontOfficeSections.section1.dailyGoal.value
                                  ? frontOfficeSections.section1.dailyGoal.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-info scheduled-production-info">
                        <h6>
                          {frontOfficeSections.section1.scheduleGoal.title
                            ? frontOfficeSections.section1.scheduleGoal.title
                            : ""}
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {frontOfficeSections.section1.scheduleGoal.value
                                ? frontOfficeSections.section1.scheduleGoal
                                    .value
                                : ""}
                            </span>
                            <div className="status-col">
                              {/* <div className="prev-status">
                                <span className="icon">
                                  <span className="arrow-up"></span>
                                </span>
                                <span className="status-value green-txt">$1,000 (27%)</span>
                                <span className="status-txt">From Previous WTD</span>
                            </div> */}
                              <div className="next-status">
                                <span
                                  className={`icon ${frontOfficeSections.section1.aboveGoal
                                    .iconClass
                                    ? frontOfficeSections.section1.aboveGoal
                                      .iconClass
                                    : ""
                                    }`}
                                >
                                  {/* <i class="fa fa-caret-up"></i> */}
                                  <span
                                    className={
                                      frontOfficeSections.section1.aboveGoal
                                        .icon
                                        ? frontOfficeSections.section1.aboveGoal
                                            .icon
                                        : ""
                                    }
                                  ></span>
                                </span>
                                <span
                                  className={`status-value ${frontOfficeSections.section1.aboveGoal
                                    .iconClass
                                    ? frontOfficeSections.section1.aboveGoal
                                      .iconClass
                                    : ""
                                    }`}
                                >

                                  {frontOfficeSections.section1.aboveGoal.value
                                    ? frontOfficeSections.section1.aboveGoal
                                        .value
                                    : ""}
                                </span>
                                <span
                                  className={`status-txt ${frontOfficeSections.section1.aboveGoal
                                    .iconClass
                                    ? frontOfficeSections.section1.aboveGoal
                                      .iconClass
                                    : ""
                                    }`}
                                >
                                  {frontOfficeSections.section1.aboveGoal.title
                                    ? frontOfficeSections.section1.aboveGoal
                                        .title
                                    : ""}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className="doctor-production patients-info-box not-scheduled-box">
                  {frontOfficeSections && frontOfficeSections.section2 && (
                    <>
                      <div className="patients-info right-border">
                        <h6>{frontOfficeSections.section2.title}</h6>
                        <div className="patient-info-detail">
                          <div className="goal-col">
                            <span className="number">
                              {frontOfficeSections.section2.value}
                            </span>
                            <div className="daily-goal">
                              <h6>
                                {frontOfficeSections.section2.unscheduledProd
                                  .title
                                  ? frontOfficeSections.section2.unscheduledProd
                                      .title
                                  : ""}
                              </h6>
                              {/* <span className="number green-txt">65%</span> */}
                              <span
                                className={`number ${frontOfficeSections.section2.unscheduledProd
                                  .iconClass
                                  ? frontOfficeSections.section2
                                    .unscheduledProd.iconClass
                                  : ""
                                  }`}
                              >

                                {frontOfficeSections.section2.unscheduledProd
                                  .value
                                  ? frontOfficeSections.section2.unscheduledProd
                                      .value
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="patients-map">
                        {/* <h6>Total Visit</h6> */}
                        <div className="patients-info-map">
                          <div className="flex-fill width-100 pos-relative inner-col">
                            <ul className="donut-label list-unstyled">
                              {frontOfficeSections.section2.chart.doctorList}
                            </ul>
                            <div className="app-chart-container">
                              {/* <p className="chart-data">
                            Accepted<span>$1,249</span>
                          </p> */}
                              <div className="inner-container">
                                {/* <div id="donut-chart3" className="donut mt-0"></div> */}
                                {frontOfficeSections.section2.chart.series
                                  .length > 0 ? (
                                  <Chart
                                    id="#donut-chart3"
                                    className="donut mt-0"
                                    options={
                                      frontOfficeSections.section2.chart.options
                                    }
                                    series={
                                      frontOfficeSections.section2.chart.series
                                    }
                                    type="donut"
                                    height={240}
                                  />) : (
                                  <span style={{ fontSize: '16px' }}>No Data Found</span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  {/* </div> */}
                </div>
                <div className="doctor-production patients-info-box no-map unactive-patients">
                  {frontOfficeSections && frontOfficeSections.section3 && (
                    <>
                      <div className="patients-info">
                        <h6>
                          {frontOfficeSections.section3.title
                            ? frontOfficeSections.section3.title
                            : ""}
                        </h6>
                        <div className="patient-info-detail">
                          <div className="goal-col right-border">
                            <span className="number">
                              {frontOfficeSections.section3.value
                                ? frontOfficeSections.section3.value
                                : ""}
                            </span>
                            <div className="daily-goal ">
                              <h6>
                                {frontOfficeSections.section3.patients.title
                                  ? frontOfficeSections.section3.patients.title
                                  : ""}
                              </h6>
                              <span className="number">
                                {frontOfficeSections.section3.patients.value
                                  ? frontOfficeSections.section3.patients.value
                                  : ""}
                              </span>
                            </div>
                          </div>
                          <div className="patients-map">
                            <div className="patients-info-map  donut-center-value">
                              <div className="app-chart-container">
                                <p className="chart-data">
                                  <span>439</span>
                                </p>
                                <div className="inner-container">
                                  {frontOfficeSections.section3.chart.series
                                    .length > 0 ? (
                                    <Chart
                                      id="new-5"
                                      options={
                                        frontOfficeSections.section3.chart
                                          .options
                                      }
                                      series={
                                        frontOfficeSections.section3.chart
                                          .series
                                      }
                                      type="donut"
                                      height={240}
                                    />) : (
                                    <span style={{ fontSize: '16px' }}>No Data Found</span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className={!showFrontOfficeSectionLoading ? "d-none" : ""}>
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Font Office */}

      {/* <!-- Footer Chart Row --> */}
      <div
        id="chart-data"
        className="historical-doctor pb-0 circle-chart footer-chart-section mb-5"
      >
        <div className="container-fluid">
          <div className="row footer-chart-row">
            {/* <div className="paients-chart-col post-patients">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  Lost Patients
                </h6>
              </div>
              <div className="pb-3 chart-section">
                <div className="app-chart-container mix-bar-chart">
                  <div className="inner-container">
                    <Chart
                      id="new-5"
                      options={LostPatientsMixChart.options}
                      series={LostPatientsMixChart.series}
                      height={335}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="paients-chart-col referral-chart">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  New Patients By Referral Source
                </h6>
              </div>
              {/* <div className="pb-5 pt-5 pl-5 pr-5 chart-section" style="position: relative;"> */}
              <div className="pb-5 pt-5 pl-5 pr-5 chart-section" style={{ position: 'relative' }}>
                {referralSourceSections && referralSourceSections.section1 && (
                  <>
                    <div className="app-chart-container">
                      <div className="inner-container">
                        {referralSourceSections.section1.chart.series.length >
                        0 ? (
                          <Chart
                            id="new-5"
                            options={
                              referralSourceSections.section1.chart.options
                            }
                            series={
                              referralSourceSections.section1.chart.series
                            }
                            type="donut"
                            height={240}
                          // width={360}
                          />) : (
                          <span style={{ fontSize: '16px' }}>No Data Found</span>
                        )}
                      </div>
                    </div>
                    <ul className="donut-label list-unstyled">
                      {referralSourceSections.section1.chart.doctorList &&
                        referralSourceSections.section1.chart.doctorList}
                    </ul>
                  </>
                )}
                <div
                  className={!showReferralSourceSectionLoading ? "d-none" : ""}
                >
                  <AppSectionLoader />
                </div>
              </div>
            </div>
            <div className="paients-chart-col referral-chart">
              <div className="">
                <h6 className="blue-title-h6 blue-title-h6-blue mb-lg-1 mb-0 mt-2">
                  Production By Referral Source
                </h6>
              </div>
              {/* <div className="pb-5 pt-5 pl-5 pr-5 chart-section" style="position: relative;"> */}
              <div className="pb-5 pt-5 pl-5 pr-5 chart-section" style={{ position: 'relative' }}>
                {referralSourceSections && referralSourceSections.section2 && (
                  <>
                    <div className="app-chart-container">
                      <div className="inner-container">
                        {referralSourceSections.section2.chart.series.length >
                        0 ? (
                          <Chart
                            id="new-5"
                            options={
                              referralSourceSections.section2.chart.options
                            }
                            series={
                              referralSourceSections.section2.chart.series
                            }
                            type="donut"
                            height={240}
                          />) : (
                          <span style={{ fontSize: '16px' }}>No Data Found</span>
                        )}
                      </div>
                    </div>
                    <ul className="donut-label list-unstyled">
                      {referralSourceSections.section2.chart.doctorList &&
                        referralSourceSections.section2.chart.doctorList}
                    </ul>
                  </>
                )}
                <div
                  className={!showReferralSourceSectionLoading ? "d-none" : ""}
                >
                  <AppSectionLoader />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end collection */}
    </div>
  );
}

export default Dashboard_historical;
