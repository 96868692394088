import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import Icon1 from '../assets/images/icon-1.png';
import Icon2 from '../assets/images/icon-2.png';
import Icon3 from '../assets/images/eye.png';
import logo from '../assets/images/logo-white.svg';
import Home from '../Home';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { validateForm } from '../config/commonFun';
import Api from '../API/login';

// export default function SignInPage(){
const SignInPage = (props) => {

  //Set User Signin model
  const [userModel, setUserModel] = useState({
    Username: "",
    Password: "",
  });

  // Set Error Messages.
  const [errors, setErrors] = useState({
    Username: "",
    Password: "",
  });

  // Set password field as password.
  const [textBoxType, setTextBoxType] = useState("password");

  // Set Loader for submit button.
  const [loader, setLoader] = useState(false);

  // Error response message
  const [resMessage, setResMessage] = useState("");

  // Check for user token.
  useEffect(() => {
    if (localStorage.getItem("token") != undefined && localStorage.getItem("token").length > 0) {
      // Redirect to dashboard.
      history.push("/dashboard");
    }
  }, []);



  let history = useHistory();
  const redirectToSignUp = () => { history.push('/signup') }
  const redirectToForgotPassword = () => { history.push('/ForgotPassword') }
  const [eyeTog3, seteyeTog3] = useState("false");

  // Toggle eye icon and toggle password mode.
  const handleToggleEye = () => {
    textBoxType == "password"
      ? setTextBoxType("text")
      : setTextBoxType("password");
    seteyeTog3(!eyeTog3);
  };

  const {
    buttonLabel,
    className
  } = props;

  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);


  // Form element chnage event handler
  const handelChange = (event) => {
    let { name, value } = event.target;
    let errorValue = "";

    if (name != "Password") {
      value = value.trim();
    }
    // Set updated value to userModel
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value
    }));

    switch (name) {
      case "Username":
        if (value.length == 0) {
          errorValue = "Email or Username required.";
        }
        break;

      case "Password":
        if (value.length == 0) {
          errorValue = "Password is required.";
        }
        break;

      default:
        break;
    }

    setErrors((errors) => ({
      ...errors,
      [name]: errorValue,
    }));

  }

  // On Form submit
  const handelSubmit = (event) => {
    event.preventDefault();
    let flagError = false;

    // Check validation 
    if (userModel.Username.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["Username"]: "Email or Username required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length == 0) {
      setErrors((errors) => ({
        ...errors,
        ["Password"]: "Password is required.",
      }));
      flagError = true;
    }

    // Login User
    if (validateForm(errors) && !flagError) {
      setLoader(true);
      Api.logIn(userModel)
        .then((res) => {
          setLoader(false);
          localStorage.setItem("token", res.data.Result.Token);
          localStorage.setItem("userData", JSON.stringify(res.data.Result));
          window.location.href = "/dashboard";
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        });
    }
  }

  return (

    <section className="common-section vh-0 login-comman-sec">
      {/* <div className="container-fluid h-0"> */}
      {/* <div className="main-block align-items-center justify-content-center h-0 d-flex"> */}
      <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered sign-up-modal">
        <ModalBody className="main-block signin-block">
          <div className="login sub-block">
            <div className="row justify-content-center">
              <div className="col-lg-7 pr-lg-0">
                <div className="d-block">
                  <div className="inner-block text-center">
                    <div className="block-1">
                      <h1>
                        Sign in to <span><b>Hi5 Practice</b></span>
                      </h1>
                      <p>Welcome back! Enter your credentials to proceed </p>
                    </div>

                    <form onSubmit={handelSubmit} >
                      <div className="content">
                        {resMessage != "" && (
                          <div className="err-message main-err-message">
                            {resMessage}
                          </div>
                        )}
                        <div className="form-group">
                          <input type="text"
                            className="form-control light-border"
                            id="username"
                            placeholder="User Name / Email Address*"
                            maxLength="100"
                            name="Username"
                            value={userModel.Username}
                            onChange={handelChange}
                          />
                          <span className="input-icon1">

                            <img src={Icon1} alt="views" className="img-fluid" />

                          </span>
                          {errors.Username.length > 0 && (
                            <div className="err-message">
                              {errors.Username}
                            </div>
                          )}
                        </div>
                        <div className="form-group">
                          <span className="input-icon1">

                            <img src={Icon2} alt="views" className="img-fluid" />

                          </span>

                          <input
                            type={textBoxType}
                            className="form-control light-border pass-input"
                            id="exampleInputPassword1"
                            placeholder="Password*"
                            name="Password"
                            value={userModel.Password}
                            onChange={handelChange}
                          />

                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
                              onClick={handleToggleEye}
                            ></i>
                          </span>

                          {errors.Password.length > 0 && (
                            <div className="err-message">
                              {errors.Password}
                            </div>
                          )}
                        </div>
                        <div className="forget-link  link-purple">
                          <a href="#" className="h5 f-600" onClick={redirectToForgotPassword}>Forgot Password?</a>
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button type="submit"
                            className={`btn btn-outline-primary blue-btn-bg ${loader ? "btn-loading-state" : ""}` }
                            >
                            {loader && (
                              <Spinner style={{ verticalAlign: "middle" }} />
                            )}
                            sign in
                          </button>
                        </div>

                      </div>
                      <div className="forget-link  text-right link-purple signup-link">
                        <p>Don't have Account ?<a href="#" className="h5 f-600 pl-2" onClick={redirectToSignUp} >Sign Up</a></p>
                      </div>
                    </form>
                  </div>

                </div>
              </div>
              <div className="col-5 bg-green d-flex">
                <div className="justify-content-center align-self-center text-center">
                  <img src={logo} className="img-fluid logo-white" />
                  <h1>Welcome</h1>
                  <p>Increase profits with our proven dental marketing strategy that focuses on patient communications.</p>
                  <div className="btn-block signup-white-btn">
                    <button type="button" className="btn btn-outline-primary blue-btn-bg " onClick={redirectToSignUp} >sign up</button>
                  </div>
                  <p>Don't have an account?</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* </div> */}
      <Home />
      {/* </div> */}
    </section>

  )
}
export default SignInPage;