import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Icon1 from '../assets/images/user-green.png';
import Icon2 from '../assets/images/icon-1.png';
import Icon3 from '../assets/images/phone-green.png';
import Icon4 from '../assets/images/icon-2.png';
import Icon5 from '../assets/images/eye.png';
import Icon6 from '../assets/images/practice.png';
import Icon7 from '../assets/images/practice-address.png';
import Icon8 from '../assets/images/no-oparetion.png';
import Icon9 from '../assets/images/right-arrow.svg';
import logo from '../assets/images/logo-white.svg';
import street from '../assets/images/Street.svg';
import city from '../assets/images/city.svg';
import state from '../assets/images/State.svg';
import country from '../assets/images/Country.svg';
import ZipCode from '../assets/images/zip-code.svg';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Home from '../Home';
import { Multiselect } from "multiselect-react-dropdown";
import { validEmailRegex, validCharRegex, validPhoneRegex, validPasswordRegex, validZipCodeRegex } from '../config/appRegex';
import { validateForm } from '../config/commonFun';
import { Tooltip } from "reactstrap";
import SpecialityIcon from "../assets/images/no-oparetion.png";


import Api from '../API/signup'
import { faRoad } from '@fortawesome/free-solid-svg-icons';

// export default function SignInPage1(){
const SignInPage1 = (props) => {

  let history = useHistory();
  const redirectToSignin = () => { history.push('/signin') }

  const {
    buttonLabel,
    className
  } = props;

  
  const [modal, setModal] = useState(true);

  const toggle = () => setModal(modal);

  const [stateList, setStateList] = useState([]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
  const [passwordType, setPasswordType] = useState("password");
  const [cpasswordType, setCPasswordType] = useState("password");
  
  const [eyeTog2, seteyeTog2] = useState("false");
  const changePasswordType = () => {
    passwordType == "password"
      ? setPasswordType("text")
      : setPasswordType("password");
    seteyeTog2(!eyeTog2);
  };

  const [eyeTog3, seteyeTog3] = useState("false");
  const changeConPasswordType = () => {
    cpasswordType == "password"
      ? setCPasswordType("text")
      : setCPasswordType("password");
    seteyeTog3(!eyeTog3);
  };

  // Set step model
  const [step, setStep] = useState(1);

  // Set response message model
  const [resMessage, setResMessage] = useState("");

  // Set Loader model
  const [loader, setLoader] = useState(false);

  // Set Signup model
  const [userModel, setUserModel] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
  });

  // Set address model
  const [addressModel, setAddressModel] = useState({
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: ""
  });

  // Set Parctice model
  const [parcticeModel, setParcticeModel] = useState({
    PracticeName: "",
    Speciality: [1],
  })

  // Set user error model
  const [userModelErrors, setUserModelErrors] = useState({
    FirstName: "",
    LastName: "",
    Email: "",
    Phone: "",
    Password: "",
    ConPassword: "",
  });

  // Set address error model
  const [addressModelErrors, setAddressModelErrors] = useState({
    StreetAddress: "",
    City: "",
    State: "",
    ZipCode: ""
  });

  // Set Parctice model
  const [parcticeErrorModel, setParcticeErrorModel] = useState({
    PracticeName: "",
    Speciality: "",
  });

  const [selectedSpecialty, setSelectedSpecialty] = useState({
    Id: 0,
    Name: "",
  });

  const [specialty, setSpecialty] = useState([
    {
      Id: 0,
      Description: "",
    },
  ]);

  const [option, setOption] = useState({
    id: 0,
    name: "",
  });

  useEffect(() => {
    getSpecialities();
    getState();
  }, [step == 2]);

  useEffect(() => {
    let ops = [];
    for (let i = 0; i < specialty.length; i++) {
      let obj = { id: 0, name: "" };
      obj.id = specialty[i].Id;
      obj.name = specialty[i].Description;
      ops.push(obj);
    }
    setOption(ops);
  }, [specialty.length]);

  useEffect(() => {
    if (state.length > 0) {
      setAddressModel((addressModel) => ({
        ...addressModel,
        ["State"]: state[0].Code,
      }));
    }
  }, [state.length]);



  // Retrieve state for country
  const getState = () => {
    Api.getState("US")
      .then((res) => {
        let list = [{ value: "", label: "Select State" }];
        if (res.data && res.data.Result) {
          for (let index = 0; index < res.data.Result.length; index++) {
            const element = res.data.Result[index];
            list.push({ value: element.Code, label: element.Name });
          }
        }
        setStateList((stateList) => ([...list]));
      })
      .catch((error) => {
        setStateList((stateList) => ([{ value: "", label: "Select State" }]));
      })
  }

  // Form element change event handler
  const handelUserModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;

    if (name != "Password" && name != "ConPassword") {
      value = value.trim();
    }
    let errorValue = "";

    // Set updated value to userModel
    setUserModel((userModel) => ({
      ...userModel,
      [name]: value,
    }));

    // Input validation
    switch (name) {
      case "FirstName":
        if (value.length == 0) {
          errorValue = "First Name is required.";
        } else {
          errorValue = validCharRegex.test(value)
            ? ""
            : "Enter only characters.";
        }
        break;
      case "LastName":
        if (value.length == 0) {
          errorValue = "Last Name is required.";
        } else {
          errorValue = validCharRegex.test(value)
            ? ""
            : "Enter only characters.";
        }
        break;
      case "Email":
        if (value.length == 0) {
          errorValue = "Email is required.";
        } else {
          errorValue = validEmailRegex.test(value) ? "" : "Enter valid email.";
        }
        break;
      case "Phone":
        if (value.length == 0) {
          errorValue = "Phone number is required.";
        } else {
          errorValue = validPhoneRegex.test(value)
            ? ""
            : "Enter valid phone number.";
        }
        break;
      case "Password":
        if (value.length == 0) {
          errorValue = "Password is required.";
        } else {
          errorValue = validPasswordRegex.test(value)
            ? ""
            : "Enter valid Password.";
        }
        break;
      case "ConPassword":
        if (value.length == 0) {
          errorValue = "Password is required.";
        } else {
          errorValue =
            userModel.Password == value ? "" : "Password don't match.";
        }
        break;
      default:
        break;
    }
    setUserModelErrors((userModelErrors) => ({
      ...userModelErrors,
      [name]: errorValue,
    }));
  }

  // Address form element change event handler
  const handelAddressModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;

    // value = value.trim();

    let errorValue = "";

    // Set updated value to userModel
    setAddressModel((addressModel) => ({
      ...addressModel,
      [name]: value,
    }));

    // Input validation
    switch (name) {
      case "StreetAddress":
        if (value.trim().length == 0) {
          errorValue = "Street Address is required.";
        }
        break;
      case "City":
        if (value.trim().length == 0) {
          errorValue = "City is required.";
        }
        break;
      case "State":
        if (value.trim().length == 0) {
          errorValue = "State is required.";
        }
        break;
      case "ZipCode":
        if (value.trim().length == 0) {
          errorValue = "Zip Code is required.";
        } else {
          errorValue = validZipCodeRegex.test(value)
            ? ""
            : "Enter valid Zip Code.";
        }
      default:
        break;
    }

    setAddressModelErrors((addressModelErrors) => ({
      ...addressModelErrors,
      [name]: errorValue,
    }));
  }

  // Practic for element change event handler
  const handelParcticModelChange = (event) => {
    event.persist();
    let { name, value } = event.target;

    // value = value.trim();

    let errorValue = "";

    // Set updated value to userModel
    setParcticeModel((parcticeModel) => ({
      ...parcticeModel,
      [name]: value,
    }));

    // Input validation
    switch (name) {
      case "PracticeName":
        if (value.trim().length == 0) {
          errorValue = "Practice Name is required.";
        }
        break;
      default:
        break;
    }
    setParcticeErrorModel((parcticeErrorModel) => ({
      ...parcticeErrorModel,
      [name]: errorValue,
    }));
  }

  const onSecondPage = (event) => {
    event.preventDefault();
    let flagError = false;

    //Usermodel validation 
    if (userModel.FirstName.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["FirstName"]: "FirstName is required.",
      }));
      flagError = true;
    }
    if (userModel.LastName.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["LastName"]: "Last Name is required.",
      }));
      flagError = true;
    }
    if (userModel.Email.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["Email"]: "Email is required.",
      }));
      flagError = true;
    }
    if (userModel.Phone.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["Phone"]: "Phone is required.",
      }));
      flagError = true;
    }
    if (userModel.Password.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["Password"]: "Password is required.",
      }));
      flagError = true;
    }
    if (userModel.ConPassword.length == 0) {
      setUserModelErrors((userModelErrors) => ({
        ...userModelErrors,
        ["ConPassword"]: "Password is required.",
      }));
      flagError = true;
    }

    // Signup User
    if (validateForm(userModelErrors) && !flagError) {
      setLoader(true);

      // Check for email
      Api.getCheckEmail(userModel.Email)
        .then((res) => {
          setLoader(false);
          // If email not exist go to second step
          res.data.Result != -1
            ? setUserModelErrors((userModelErrors) => ({
              ...userModelErrors,
              ["Email"]: res.data.Message,
            }))
            : setStep(2);
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        });
    }
  };

  const goToStepOne = () => {
    setStep(1);
  }

  // Set second step for signup
  const goToStepTwo = () => {
    setStep(2);
  }

  // Set third step for signup
  const onThirdPage = (event) => {
    event.preventDefault();
    let flagError = false;
    if (addressModel.StreetAddress.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["StreetAddress"]: "Street Address is required.",
      }));
      flagError = true;
    }
    if (addressModel.City.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["City"]: "City is required.",
      }));
      flagError = true;
    }
    if (addressModel.State == null || addressModel.State == undefined || addressModel.State.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["State"]: "State is required.",
      }));
      flagError = true;
    }
    if (addressModel.ZipCode.length == 0) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["ZipCode"]: "Zip Code is required.",
      }));
      flagError = true;
    } else if (!validZipCodeRegex.test(addressModel.ZipCode)) {
      setAddressModelErrors((addressModelErrors) => ({
        ...addressModelErrors,
        ["ZipCode"]: "Enter valid Zip Code."
      }));
      flagError = true;
    }

    if (validateForm(addressModelErrors) && !flagError) {
      setStep(3);
    }
  }

  // User Signup 
  const signUp = (event) => {
    event.preventDefault();

    let flagError = false;
    if (parcticeModel.PracticeName.length == 0) {
      setParcticeModel((parcticeModel) => ({
        ...parcticeModel,
        ["PracticeName"]: "Street Address is required.",
      }));
      flagError = true;
    }

    if (!flagError) {
      setLoader(true);
      let userInfo = {
        ...userModel,
        Address: {
          ...addressModel
        },
        ...parcticeModel
      }
      Api.signUp(userInfo)
        .then((res) => {
          setLoader(false);
          if (res.data.IsSuccess) {
            setResMessage(
              "Please check your email for a confirmation email to login to your account."
            );
            setTimeout(() => redirectToSignin(), 10000);
          }
          redirectToSignin();
        })
        .catch((error) => {
          setLoader(false);
          setResMessage(JSON.parse(error.request.response).Message);
        })
    }
  }

  const onSpeSelect = (obj, newObj) => {
    setParcticeErrorModel((errors) => ({
      ...errors,
      ["Speciality"]: "",
    }));
    setParcticeModel((userModel) => ({
      ...userModel,
      ["Speciality"]: obj.map((a) => a.id),
    }));
  };
  const onSpeRemove = (obj, reobj) => {
    if (obj.length == 0) {
      setParcticeErrorModel((errors) => ({
        ...errors,
        ["Speciality"]: "Specialty is required",
      }));
    }
    parcticeModel((userModel) => ({
      ...userModel,
      ["Speciality"]: obj.map((a) => a.id),
    }));
  };

  const getSpecialities = () => {
    Api.getSpecialities()
      .then((res) => {
        setSpecialty(res.data.Result);
      })
      .catch((error) => { });
  };


  return (<section className="common-section vh-0 login-comman-sec">

    <Modal isOpen={modal} toggle={toggle} className="modal-dialog-centered  sign-up-modal">
      <ModalBody className="main-block signin-block signup1">

        <div className="login sub-block">
          <div className="row justify-content-center">
            <div className="col-5 bg-green bg-green-1 d-flex">
              <div className="justify-content-center align-self-center text-center">
                <img src={logo} className="img-fluid logo-white" />
                <h1>Welcome Back!</h1>
                <p>Increase profits with our proven dental marketing strategy that focuses on patient communications.</p>

                <div className="btn-block signup-white-btn">
                  <button type="button" className="btn btn-outline-primary blue-btn-bg " onClick={redirectToSignin} >sign in</button>
                </div>
              </div>
            </div>
            <div className="col-lg-7 pl-lg-0">
              <div className="d-block">
                {step == 1 ? (
                  <div className="inner-block text-center sign-up">
                    <div className="block-1">
                      <h1>
                        Sign up to <span><b>Hi5 Practice</b></span>
                      </h1>
                      <p className="mb-2">Enter your personal information </p>
                    </div>
                    <form>
                      <div className="content">
                        <div className="form-group">
                          <div className="position-relative">

                            <input
                              type="text"
                              className="form-control light-border"
                              id="firstName"
                              name="FirstName"
                              maxLength="20"
                              placeholder="First Name*"
                              value={userModel.FirstName}
                              onChange={handelUserModelChange}
                            />
                            <span className="input-icon1">
                              <img src={Icon1} alt="views" className="img-fluid" />
                            </span>
                          </div>
                          <div className="err-message">
                            {userModelErrors.FirstName}
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="position-relative">
                            <input
                              type="text"
                              className="form-control light-border"
                              id="lastName"
                              name="LastName"
                              maxLength="20"
                              placeholder="Last Name*"
                              value={userModel.LastName}
                              onChange={handelUserModelChange}
                            />
                            <span className="input-icon1">
                              <img src={Icon1} alt="views" className="img-fluid" />
                            </span>
                          </div>
                          <div className="err-message">
                            {userModelErrors.LastName}
                          </div>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="email"
                            name="Email"
                            maxLength="100"
                            placeholder="Email Address*"
                            value={userModel.Email}
                            onChange={handelUserModelChange}
                          />
                          <span className="input-icon1">
                            <img src={Icon2} alt="views" className="img-fluid" />
                          </span>
                        </div>
                        <div className="err-message">{userModelErrors.Email}</div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="phoneNumber"
                            placeholder="Phone Number*"
                            name="Phone"
                            value={userModel.Phone}
                            onChange={handelUserModelChange}
                          />
                          <span className="input-icon1">
                            <img src={Icon3} alt="views" className="img-fluid" />
                          </span>
                        </div>
                        <div className="err-message">{userModelErrors.Phone}</div>
                        <div className="form-group" id="TooltipExample">
                          <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen}
                            target="TooltipExample"
                            toggle={toggleTooltip}
                          >
                            Password must be a minimum of 8 characters long
                            and contain an upper and lower case letter, a
                            number and a symbol.
                          </Tooltip>
                          <span className="input-icon1">
                            <img src={Icon4} alt="views" className="img-fluid" />
                          </span>
                          <input
                            type={passwordType}
                            className="form-control light-border pass-input"
                            id="password"
                            maxLength="50"
                            placeholder="Password*"
                            name="Password"
                            value={userModel.Password}
                            onChange={handelUserModelChange}
                          />
                          {/* <span className="input-icon">
                            <img src={Icon5} alt="views" className="img-fluid" />
                        </span> */}
                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog2 ? "passVisible" : ""}`}
                              onClick={changePasswordType}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">{userModelErrors.Password}</div>
                        <div className="form-group">
                          <span className="input-icon1">
                            <img src={Icon4} alt="views" className="img-fluid" />
                          </span>
                          <input
                            type={cpasswordType}
                            className="form-control light-border pass-input"
                            id="conPassword"
                            maxLength="50"
                            placeholder="Confirm Password*"
                            name="ConPassword"
                            value={userModel.ConPassword}
                            onChange={handelUserModelChange}
                          />
                          {/* <span className="input-icon">
                        <img src={Icon5} alt="views" className="img-fluid" />

                        </span> */}
                          <span className="input-icon">
                            <i
                              className={`passTogEye icon-eye ${!eyeTog3 ? "passVisible" : ""}`}
                              onClick={changeConPasswordType}
                            ></i>
                          </span>
                        </div>
                        <div className="err-message">
                          {userModelErrors.ConPassword}
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="button"
                            className={`btn btn-outline-primary blue-btn-bg ${loader ? "btn-loading-state" : ""}` }
                            onClick={onSecondPage}
                          >
                            <div className="button-inner">
                            {loader && (
                              <Spinner
                                style={{ verticalAlign: "middle" }}
                              />
                            )}
                            <span className="btn-text">next</span>
                            </div>
                        </button>
                        </div>
                      </div>
                      <div className="forget-link  text-right link-purple signup-link">
                        <p>Already have Account?<a href="#" className="h5 f-600 pl-2" onClick={redirectToSignin}>Sign in</a></p>
                      </div>
                    </form>
                  </div>
                ) : step == 2 ? (
                  <div className="inner-block text-center sign-up">
                    <div className="block-1">
                      <h1>
                        Sign up to <span><b>Hi5 Practice</b></span>
                      </h1>
                      <p className="mb-2">Enter your Practice Address</p>
                    </div>
                    <form>
                      <div className="content">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="streetAddress"
                            placeholder="Street Address*"
                            name="StreetAddress"
                            value={addressModel.StreetAddress}
                            onChange={handelAddressModelChange}
                          />
                          <span className="input-icon1">
                            <img src={street} alt="views" className="img-fluid" />
                          </span>
                        </div>
                        <div className="err-message">
                          {addressModelErrors.StreetAddress}
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="city"
                            placeholder="City*"
                            name="City"
                            value={addressModel.City}
                            onChange={handelAddressModelChange}
                          />
                          <span className="input-icon1">
                            <img src={city} alt="views" className="img-fluid" />
                          </span>
                        </div>
                        <div className="err-message">
                          {addressModelErrors.City}
                        </div>
                        <div className="form-group select-form-group">
                          <span className="input-icon1">
                            <img src={state} alt="views" className="img-fluid" />
                          </span>

                          <select className="form-control" name="State" value={addressModel.State} onChange={handelAddressModelChange}>
                            {stateList.map((item) => (<option value={item.value} key={item.value} >{item.label}</option>))}
                          </select>

                        </div>
                        <div className="err-message">
                          {addressModelErrors.State}
                        </div>
                        {/* <div className="form-group disabled">
                        <input type="text" className="form-control light-border" id="country" placeholder="Country*" defaultValue="United States" disabled />
                          <span className="input-icon1">
                              <img src={country} alt="views" className="img-fluid" />
                          </span>
                      </div> */}
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control light-border"
                            id="zipCode"
                            placeholder="Zip Code*"
                            name="ZipCode"
                            value={addressModel.ZipCode}
                            onChange={handelAddressModelChange}
                          />
                          <span className="input-icon1">
                            <img src={ZipCode} alt="views" className="img-fluid" />
                          </span>
                        </div>
                        <div className="err-message">
                          {addressModelErrors.ZipCode}
                        </div>
                      </div>
                      <div className="account-btn-block">
                        <div className="btn-block">
                          <button
                            type="button"
                            className={`btn btn-outline-primary blue-btn-bg ${loader ? "btn-loading-state" : ""}` }
                            onClick={onThirdPage}
                          >
                            <div className="button-inner">
                            {loader && (
                              <Spinner
                                style={{ verticalAlign: "middle" }}
                              />
                            )}
                            <span className="btn-text">next</span>
                            </div>
                            </button>
                        </div>
                      </div>
                      {/* <div className="account-btn-block">
                      <div className="btn-block">
                        <button type="button" className="btn btn-outline-primary   blue-btn-bg ">Sign up</button>
                      </div>
                    </div> */}
                      {/* <div className="forget-link  text-right link-purple signup-link">
                      <p>Already have Account ?<a href="#" className="h5 f-600 pl-2">Sign in</a></p>
                    </div> */}
                      <div className="account-btn-block text-center pt-2">
                        <a
                          className="back-button mr-auto pl-2"
                          onClick={goToStepOne}
                        >
                          <img src={Icon9} alt="views" className="img-fluid" />
                          Back
                      </a>
                      </div>
                    </form>
                  </div>
                ) : (
                      <div className="inner-block text-center ">
                        <div className="block-1">
                          <h1>
                            Sign up to <span><b>Hi5 Practice</b></span>
                          </h1>
                          <p className="mb-2">Enter your Practice information</p>
                        </div>
                        <div>{resMessage}</div>
                        <form>
                          <div className="content">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control light-border"
                                id="practiceName"
                                placeholder="Practice Name*"
                                name="PracticeName"
                                value={parcticeModel.PracticeName}
                                onChange={handelParcticModelChange}
                              />
                              <span className="input-icon1">
                                <img src={Icon6} alt="views" className="img-fluid" />
                              </span>
                            </div>
                            <div className="err-message">
                              {parcticeErrorModel.PracticeName}
                            </div>

                            <div className="form-group has-value select-form-group">
                              <Multiselect
                                className="multi-select"
                                options={option} // Options to display in the dropdown
                                showCheckbox={true}
                                placeholder="Select Dental Specialty"
                                displayValue="name"
                                closeOnSelect={false}
                                onSelect={onSpeSelect}
                                onRemove={onSpeRemove}
                              />
                              <span className="input-icon1">
                                <img
                                  src={Icon8}
                                  alt="views"
                                  className="img-fluid"
                                />
                              </span>
                              <span className="select-down-arrow">
                                <i
                                  className="fa fa-chevron-down"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            </div>
                            <div className="err-message">
                              {parcticeErrorModel.Speciality}
                            </div>

                          </div>
                          <div className="account-btn-block">
                            <div className="btn-block">
                              <button type="button" className={`btn btn-outline-primary blue-btn-bg ${loader ? "btn-loading-state" : ""}` } onClick={signUp}>
                                <div className="button-inner">
                                {loader && (
                                  <Spinner
                                    style={{ verticalAlign: "middle" }}
                                  />
                                )}
                                </div>
                                <span className="btn-text">Sign up</span>
                            </button>
                            </div>
                          </div>
                          <div className="forget-link  text-right link-purple signup-link">
                            <p>Already have Account ?<a href="#" className="h5 f-600 pl-2" onClick={redirectToSignin}>Sign in</a></p>
                          </div>
                          <div className="account-btn-block text-center pt-2">
                            <a onClick={goToStepTwo} className="back-button mr-auto pl-2">
                              {/* <i className="fa fa-chevron-left" aria-hidden="true"></i> */}
                              <img src={Icon9} alt="views" className="img-fluid" />
                              Back
                      </a>
                          </div>
                        </form>
                      </div>

                    )}


              </div>
            </div>

          </div>

        </div>
      </ModalBody>
    </Modal>
    <Home />
    {/* </div>
    </div> */}
  </section>)
}
export default SignInPage1;