import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
// import DatePicker from "react-datepicker";
import FeatherIcon from 'feather-icons-react';
// component imports
import AppSidebarNavbar from '../common-components/AppSidebarNavbar';
import AddLocationProfileModel from '../models/AddLocationProfileModel';
import EditLocationProfileModel from '../models/EditLocationProfileModel';
import EditUserProfileModel from '../models/EditUserProfileModel';
import AppSectionLoader from "../common-components/AppSectionLoader";
import AddProfilePicModal from "../models/AddProfilePicModal";
import renderHTML from "react-render-html";

//images import
import userThumb from "../assets/images/user-thumb-1.png";
//user-thumb-1.png

import Api from "../API/profile"

export default function Profile() {
  const [AddLocationModelShow, SetAddLocationModel] = useState(false);
  const [EditLocationModelShow, SetEditLocationModel] = useState(false);
  const [EditProfileModelShow, SetEditProfileModel] = useState(false);
  const [AddProfilePicModalShow, SetAddProfilePicModal] = React.useState(false);
  const [loaderForProfile, SetLoaderForProfile] = useState(true);
  // const [loaderForLocation, SetLoaderForLocation] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [selectedState, setSelectedState] = useState([]);

  const [user, setUser] = useState({
    Token: "",
    UserId: 0,
    FirstName: "",
    LastName: "",
    ImageUrl: "",
    AccountName: "",
    AccountId: 0,
    IsNPINumber: false,
    SubscriptionExpiry: "",
    ISSubscription: false,
  });

  const [userData, setUserdata] = useState({
    Id: 0,
    Salutation: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Image: "",
    Email: "",
    Title: "",
    WebSiteURL: "",
    Gender: "",
    Description: "",
    NpiNumber: "",
    Username: "",
    SpecialtyId: [],
    PracticeName: "",
  });

  const [selectedSpecialty, setSelectedSpecialty] = useState({
    Id: 0,
    Name: "",
  });

  const [specialty, setSpecialty] = useState({
    Id: 0,
    Description: "",
  });

  const [address, setAddress] = useState({
    State: {
      Id: 0,
      Name: "",
      Code: "",
    },
    Country: {
      Id: 0,
      Name: "",
      Code: "",
      ISOCode: "",
    },
    Id: 0,
    Name: "",
    Address1: "",
    Address2: "",
    City: "",
    ZipCode: "",
    Email: "",
    Phone: "",
    Fax: "",
    ContactType: 0,
    NPINumber: "",
  });

  const [state, setState] = useState({
    Id: 1,
    Name: "",
    Code: "",
  });

  const getProfileDetails = (id) => {
    if (id > 0) {
      SetLoaderForProfile(true);
      Api.getProfile(id)
        .then((res) => {
          SetLoaderForProfile(false);
          setUserdata(res.data.Result);
        })
        .catch((error) => {
          SetLoaderForProfile(false);
        });
    }
  };

  const getAddress = (id) => {
    if (id > 0) {
      // SetLoaderForLocation(true);
      Api.getAddress(id)
        .then((res) => {
          let result = res.data.Result;
          if (result.length > 0) {
            result.sort((a, b) => b.ContactType - a.ContactType);
          }
          setAddress(result);
          // SetLoaderForLocation(false);
        })
        .catch((error) => {
          // SetLoaderForLocation(false);
        });
    }
  };

  const getSpecialities = () => {
    Api.getSpecialities()
      .then((res) => {
        setSpecialty(res.data.Result);
      })
      .catch((error) => { });
  };

  const getSelectedSpecialty = (id) => {
    if (id > 0) {
      Api.getSelectedSpecialty(id)
        .then((res) => {
          setSelectedSpecialty(res.data.Result);
        })
        .catch((error) => { });
    }
  };

  const getState = () => {
    Api.getState("US")
      .then((res) => {
        setState(res.data.Result);
      })
      .catch((error) => { });
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("userData")));
    getProfileDetails(user.UserId);
    getAddress(user.UserId);
    getSpecialities();
    getSelectedSpecialty(user.UserId);
    getState();
  }, [user.UserId]);

  const reload = () => {
    let data = JSON.parse(localStorage.getItem("userData"));
    setUserdata((userData) => ({
      ...userData,
      ...data,
      Image: data.ImageUrl
    }));
    getSelectedSpecialty(data.UserId);
  };

  const reloadAddress = () => {
    let data = JSON.parse(localStorage.getItem("userData"));
    getAddress(data.UserId);
  }

  const addEditLocation = (en, id) => {
    SetEditLocationModel(en);
    setSelectedLocation(address.find((a) => a.Id == id));
    setSelectedState(state);
  };

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div className="main-wrapper">
        {/* <AppSidebarNavbar userImg={userData.Image} /> */}
        <AppSidebarNavbar />
        <main>
          <div className="app-container">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <nav aria-label="breadcrumb" className="app-breadcrumb req-mar mr-1">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page"> Profile</li>
                </ol>
              </nav>
              <NavLink exact to="/" className="page-back-button">
                <i className="fa fa-chevron-left"></i> <span>Back to Dashboard</span>
              </NavLink>
            </div>
            <div className="app-content">
              <div className="profile-page">
                <div className="white-container d-flex">
                  <div className="user-information">
                    <div className="inner-container">
                      <div className="sec-header">
                        <h4 className="mr-auto">User Information</h4>
                        <button
                          className="app-btn app-btn-clear edit-btn "
                          onClick={() => SetEditProfileModel(true)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 16 17"
                          >
                            <path
                              fill="#707070"
                              d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="user-img-wrapper ">
                        <div
                          className="user-img"
                          style={{ backgroundImage: 'url('+encodeURI(userData.Image)+')' }}
                        >
                          <div
                            className="upload-btn"
                            onClick={() => SetAddProfilePicModal(true)}
                          ></div>
                        </div>
                        <h4 className="user-name">
                          {userData.FirstName + " " + userData.LastName}
                        </h4>
                        <h4 className="user-prof">
                          {selectedSpecialty.length > 0 &&
                            selectedSpecialty.map((a) => a.Name).join(", ")}
                        </h4>
                      </div>
                      <div className="user-basic-info active">
                        <div className="info-row">
                          <span>First Name</span>
                          <label>{userData.FirstName} </label>
                        </div>
                        <div className="info-row">
                          <span>Last Name</span>
                          <label>{userData.LastName} </label>
                        </div>
                        <div className="info-row">
                          <span>Specialty</span>
                          <label>
                            {selectedSpecialty.length > 0 &&
                              selectedSpecialty.map((a) => a.Name).join(", ")}
                          </label>
                        </div>
                        <div className="info-row">
                          <span>User Name</span>
                          <label>{userData.Username} </label>
                        </div>
                        <div className="info-row">
                          <span>Email</span>
                          <label>
                            <a
                              className="contact-link"
                              href={`mailto:${userData.Email}`}
                            >
                              {userData.Email}
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className={!loaderForProfile ? "d-none" : ""}>
                      <AppSectionLoader />
                    </div>
                  </div>
                  <div className="user-location">
                    <div className="inner-container px-0 pb-0">
                      <div className="sec-header">
                        <h4 className="mr-auto">Locations</h4>
                        <button
                          className="app-btn app-btn-clear add-btn "
                          onClick={() => addEditLocation(true)}
                        >
                          + Add New Location
                          </button>
                      </div>
                      <div className="location-container">
                        {address.length > 0 &&
                          address.map((add, i) => (
                            <div
                              className={
                                add.ContactType == 1
                                  ? "location-info active"
                                  : "location-info"
                              }
                            >
                              <button
                                className="app-btn app-btn-clear edit-btn "
                                onClick={() => addEditLocation(true, add.Id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 17"
                                >
                                  <path
                                    fill="#707070"
                                    d="M0 12.756v3.27h3.27l9.647-9.648-3.27-3.27L0 12.756zM15.745 2.29L13.709.256a.874.874 0 00-1.234 0L10.88 1.851l3.27 3.27 1.595-1.596a.874.874 0 000-1.234z"
                                  />
                                </svg>
                              </button>
                              <h4>
                                {add.Name}{" "}
                                {add.ContactType == 1 && (
                                  <small>(Primary)</small>
                                )}
                              </h4>
                              <p>
                                {add.Address1}
                                {renderHTML(
                                  add.Address2.length > 0
                                    ? "<br /> " + add.Address2
                                    : ""
                                )}
                                <br />
                                {add.State != "" &&
                                  add.State != null &&
                                  add.State.Code.length > 0
                                  ? add.State.Code + ", "
                                  : ""}
                                {add.ZipCode}
                              </p>
                              <a
                                className="contact-link"
                                href={`mailto:${add.Email}`}
                              >
                                {add.Email}
                              </a>
                              <a
                                className="contact-link"
                                href={`tel:${add.Phone}`}
                              >
                                {add.Phone}
                              </a>
                              <p>NPI Number :{add.NPINumber}</p>
                            </div>
                          ))}
                      </div>
                    </div>
                    {/* <div className={!loaderForLocation ? "d-none" : ""}>
                        <AppSectionLoader />
                      </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <AddLocationProfileModel
          show={AddLocationModelShow}
          onHide={() => SetAddLocationModel(false)}
        />
        <EditLocationProfileModel
          show={EditLocationModelShow}
          address={selectedLocation}
          state={selectedState}
          reload={reloadAddress}
          onHide={() => SetEditLocationModel(false)}
        />
        <EditUserProfileModel
          show={EditProfileModelShow}
          userData={userData}
          selectedSpecialty={selectedSpecialty}
          specialty={specialty}
          reload={reload}
          onHide={() => SetEditProfileModel(false)}
        />
        <AddProfilePicModal
          show={AddProfilePicModalShow}
          onHide={() => SetAddProfilePicModal(false)}
          reload={reload}
        />
      </div>
    </>
  );
}